import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';
import ReactToPrint from 'react-to-print';
import QRRender from './QRRender';

export class QRView extends Component {
    constructor(props) {
        super(props);
        let self = this;
        self.url = `https://guest.rs/meni?restoran=${self.props._idRestorana}`;
        self.state = { opcija: 1 };

    }
    render() {
        let self = this;
        return (<div>
            <ReactToPrint
                trigger={() => {
                    return <Button>Štampaj</Button>;
                }}
                content={() => this.componentRef}
            />
            <Form.Select aria-label="Odaberite" onChange={(e) => {
                self.setState({ opcija: e.target.value });
                console.log(e.target.value);
                this.componentRef.render();
            }}>
                <option value={1}>Opcija 1</option>
                <option value={2}>Opcija 2</option>
                <option value={3}>Opcija 3</option>
            </Form.Select>
            <QRRender
                url={self.url}
                opcija={this.state.opcija}
                ref={el => (this.componentRef = el)}
            >
            </QRRender>
        </div>
        );
    }
}