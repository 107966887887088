import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
  //,Redirect
} from "react-router-dom";
import { Narudzbine } from "./pages/Narudzbine";
import Cookies from "universal-cookie";
import { io } from "socket.io-client";
import { Prijava } from "./pages/LandingPages/Prijava";
import { Registracija } from "./pages/LandingPages/Registracija";
import { Meni } from "./pages/Meni";
import { Statistika } from "./pages/Statistika";
import { Upravljanje } from "./pages/Upravljanje";
import { AboutUs } from "./pages/LandingPages/AboutUs";
import { Contact } from "./pages/LandingPages/Contact";
import { Pricing } from "./pages/LandingPages/Pricing";
import "./App.css";
import { isMobile } from "react-device-detect";
import { ChangePassword } from "./pages/ChangePassword";
import { Navbar2 } from "./Components/Navbar2";

import { LandingPage } from "./pages/LandingPages/LandingPage";

export default class App extends Component {
  constructor(props) {
    super(props);
    let self = this;
    global.drzave = [//V0
      {
        id: 0,
        lokalniNaziv: "Srbija",
        gradovi: [0, 2],
        lokacija: {
          lat: 44.787197,
          lng: 20.457273
        },
        valuta: 0
      },
      {
        id: 1,
        lokalniNaziv: "Crna Gora",
        gradovi: [1],
        lokacija: {
          lat: 42.442574,
          lng: 19.268646
        },
        valuta: 1
      },
    ];
    global.gradovi = [//V0
      {
        id: 0,
        lokalniNaziv: "Beograd",
        drzava: 0,
        lokacija: {
          lat: 44.787197,
          lng: 20.457273
        },
      },
      {
        id: 1,
        lokalniNaziv: "Herceg Novi",
        drzava: 1,
        lokacija: {
          lat: 42.45306,
          lng: 18.5375
        },
      },
      {
        id: 2,
        lokalniNaziv: "Kragujevac",
        drzava: 0,
        lokacija: {
          lat: 44.012793,
          lng: 20.911423
        },
      },
    ]
    self.state = {
      loggedIn: false,
      loading: true
    };
  }
  componentDidMount() {
    document.title = "Guest";
    let self = this;
    global.mobilni = isMobile;
    self.verzija = -1;
    global.cookies = new Cookies();
    const SERVIP = "guest.rs";
    let testvar = 0;
    global.socket = io(testvar ? "http://192.168.1.18:2000" : ("https://" + SERVIP + ":443"), {
    });
    global.socket.on("zatraziAutentikaciju", function (podaci) {
      console.log("ZATRAZENA AUTENT");
      if (self.verzija == -1) {
        self.verzija = podaci.verzija;
      }
      else if (self.verzija != podaci.verzija) {
        window.location.reload();
      }
      if (global.restoran) {

        global.socket.emit("autentikacija", { konzola: "Restoran", _id: global.restoran._id, kod_sesije: global.restoran.kod_sesije });
      }
      else {
        global.socket.emit("autentikacija", { konzola: "Restoran" });
      }
    })
    global.socket.on("odjavljivanjeRestorana", function () {
      alert("OVO BRISANJE");
      global.cookies.set('_id', "", { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
      global.cookies.set('kod_sesije', "", { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
      window.location.reload(false);
    });
    global.functions = {};
    global.functions.login = () => {
      self.setState({ loggedIn: true });
    };
    let _id = global.cookies.get("_id");
    let kod_sesije = global.cookies.get("kod_sesije");
    self.setState({ loading: true, loggedIn: false })
    global.restoran = {};
    global.restoran._id = _id;
    global.restoran.kod_sesije = kod_sesije;
    console.log(global.restoran);

    //global.socket.emit("autentikacija", { konzola: "Restoran", _id: _id, kod_sesije: kod_sesije });
    global.socket.once("odgovorNaAutentikaciju", function (podaci) {
      console.log("ODG NA AUT");
      console.log(podaci);
      let pn = window.location.pathname.toLowerCase();
      if (podaci.uspeh) {
        let ks = global.restoran.kod_sesije;
        global.restoran = podaci.restoran;
        global.restoran.kod_sesije = ks;
        console.log("WINLOC");
        //if(window.location.pathname==)
        if (pn.includes("prijava")
          || pn.includes("registracija")
          || pn.includes("aboutus")
          || pn.includes("contact")
          || pn.includes("pricing")) {
          //global.socket.disconnect();
          //window.location.assign("/restoran/upravljanje");
          //window.location.replace("/restoran/upravljanje");
        }
        console.log("OVDE 123");
        self.setState({ loading: false, loggedIn: true });
      }
      else {
        if (pn.includes("meni")
          || pn.includes("narudzbine")
          || pn.includes("statistika")
          || pn.includes("contact")
          || pn.includes("upravljanje")) {
          //global.socket.disconnect();
          //window.location.assign("/restoran/");
          //window.location.replace("/restoran");
        }
        self.setState({ loading: false, loggedIn: false });
      }
    })
  }
  render() {
    let self = this;
    console.log(self.state);
    if (self.state.loading) {
      return <div>Molimo sačekajte...</div>;
    }
    else if (!self.state.loggedIn) {
      return (
        <Router basename="/restoran">
          <Switch>
            <Route path="/prijava">
              <Prijava />
            </Route>
            <Route path="/registracija">
              <Registracija />
            </Route>
            <Route path="/aboutUs">
              <AboutUs />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/">
              <LandingPage></LandingPage>
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return (

        <Router basename="/restoran">


          <Switch>
            {!isMobile ? <Route path="/meni">
              <Navbar2 />
              <Meni />
            </Route> : null}
            <Route path="/narudzbine">
              <Narudzbine />
            </Route>
            <Route path="/statistika">
              <Navbar2 />
              <Statistika />
            </Route>
            <Route path="/upravljanje">
              <Navbar2 />
              <Upravljanje />
            </Route>
            <Route path="/lozinka">
              <Navbar2 />
              <ChangePassword />
            </Route>
            <Route path="/">
              <Narudzbine />
            </Route>

          </Switch>
        </Router>

      );
    }
  }
}