import { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar1 } from "../../Components/Navbar1";
import { Button } from 'react-bootstrap'
import '../../Styles/LogReg.css'
import Geocode from "react-geocode";

import { isMobile } from "react-device-detect";
//var headers = new Headers();
//headers.append("X-CSCAPI-KEY", "API_KEY");
Geocode.setApiKey("AIzaSyBAGL_ry1Vk9ubUmbPrLO9HxvQ9T4b0_wc");

/*var requestOptions = {
    method: 'GET',
    headers: headers,
    redirect: 'follow'
};*/
export class Registracija extends Component {
    constructor(props) {
        super(props)
        this.state = { naziv: "", adresa: "", mejl: "", telefon: "", grad: "", drzava: "", sifra: "", confSifra: "", contries: [], cities: [], loading: true }
    }

    fetchData = async () => {
        /*return fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
            .then(response => response.text())
            .then(result => {})
            .catch(error => {});*/
    }
    async componentDidMount() {
        let self = this;
        self.setState({ countries: global.drzave, loading: false });
        /*global.socket.emit("Z atraziListuDrzava")
        global.socket.once("StiglaListaDrzava", function (rezultat) {
            self.setState({ countries: rezultat, loading: false });
        })*/

    }
    onchangeText(txt, stanje) {
        const x = stanje
        this.setState({ x: txt })
    }

    nextPath(path) {
        this.props.history.push(path);
    }
    render() {
        let self = this;
        if (self.state.loading) return (<div>Ucitavanje</div>);
        return (
            <div>
                <Navbar1 />
                <div class="container register" style={{ borderRadius: 20, height: '80vh' }}>
                    <div class="row" style={{}}>
                        <div class="col-md-3 register-left">
                            <img src="https://i.ibb.co/PjgRgmQ/burger.png" style={{ borderRadius: 10 }} alt="" />
                            <h3>Dobrodošli</h3>
                            <p style={{ color: "gray" }}>Na korak do bolje usluge!</p>
                            <button onClick={() => { }} type="submit" className="btn btn-primary" style={{ backgroundColor: 'black', borderColor: 'gray', width: '100%', color: 'white', borderWidth: 0, borderRadius: 10, marginBottom: 10 }}>
                                <Link to='/prijava' style={{ color: "#ffffff", textDecoration: "none" }}>Prijavite se</Link>
                            </button><br />
                        </div>
                        <div class="col-md-9 register-right" style={{ borderRadius: 20 }}>

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <h3 class="register-heading">Registrujte se kao restoran</h3>
                                    <div class="row register-form" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div class="col-md-6" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }} >
                                            <div class="form-group" style={{ marginBottom: 10 }}>
                                                <input
                                                    value={self.state.naziv}
                                                    onChange={(event) => {
                                                        self.setState({ naziv: event.target.value })
                                                    }}
                                                    class="form-control" placeholder="Naziv restorana *" />
                                            </div>
                                            <div class="form-group" style={{ marginBottom: 10 }}>
                                                <input
                                                    value={self.state.mejl}
                                                    onChange={(event) => {
                                                        self.setState({ mejl: event.target.value })
                                                    }}
                                                    type="text" class="form-control" placeholder="Email *" />
                                            </div>
                                            <div class="form-group" style={{ marginBottom: 10 }}>

                                                <input
                                                    value={this.state.sifra}
                                                    onChange={(event) => {
                                                        self.setState({ sifra: event.target.value })
                                                    }}
                                                    type="password" class="form-control" placeholder="Lozinka *" />
                                            </div>
                                            <div class="form-group" style={{ marginBottom: 10 }}>
                                                <input
                                                    value={this.state.confSifra}
                                                    onChange={(event) => {
                                                        self.setState({ confSifra: event.target.value })
                                                    }}
                                                    type="password" class="form-control" placeholder="Potvrdite lozinku *" />
                                            </div>

                                        </div>
                                        <div class="col-md-6"  >
                                            <div class="form-group" style={{ marginBottom: 10 }} >
                                                <input
                                                    value={self.state.telefon}
                                                    onChange={(event) => {
                                                        self.setState({ telefon: event.target.value })
                                                    }}
                                                    type="phone" class="form-control" placeholder="Broj telefona restorana *" />
                                            </div>
                                            <div class="form-group" style={{ marginBottom: 10 }}>
                                                <input
                                                    value={this.state.adresa}
                                                    onChange={(event) => {
                                                        self.setState({ adresa: event.target.value })
                                                    }}
                                                    type="text" name="adress" class="form-control" placeholder="Adresa restorana *" />
                                            </div>
                                            <div class="form-group" style={{ marginBottom: 10, marginTop: 10 }}>
                                                <select
                                                    onSelect={(text) => { this.setState({ drzava: text }) }}
                                                    class="form-control"
                                                    value={self.state.drzava}
                                                    onChange={function (event) {
                                                        self.setState({ drzava: event.target.value, grad: "" });
                                                        self.setState({ cities: global.drzave[event.target.value].gradovi })
                                                        /*global.socket.emit("Z atraziListuGradova", { drzava: event.target.value });
                                                        global.socket.once("StiglaListaGradova", function (podaci) {
                                                            self.setState({ cities: podaci });
                                                        })*/
                                                    }}>

                                                    <option value={""} class="hidden" disabled>Izaberite zemlju</option>
                                                    {self.state.countries.map((country) => {
                                                        return (<option key={country.id} value={country.id}>{country.lokalniNaziv}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control"
                                                    onChange={function (event) {
                                                        self.setState({ grad: event.target.value });
                                                    }}
                                                    value={self.state.grad}>
                                                    <option class="hidden" value={""} disabled>Izaberite grad</option>

                                                    {self.state.cities.map((city) => {
                                                        return (<option key={global.gradovi[city].id} value={global.gradovi[city].id}>{global.gradovi[city].lokalniNaziv}</option>)
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                        <button onClick={function () {
                                            //Validacija
                                            let naziv = self.state.naziv;
                                            let adresa = self.state.adresa;
                                            let mejl = self.state.mejl;
                                            let broj_telefona = self.state.telefon;
                                            let grad = self.state.grad;
                                            let drzava = self.state.drzava;
                                            let lozinka = self.state.sifra;
                                            let ponovljenalozinka = self.state.confSifra;
                                            let lokacija = {};
                                            if (!naziv || !adresa || !mejl || !broj_telefona || !grad || !drzava || !lozinka || !ponovljenalozinka) return alert("Molimo Vas da popunite sva polja");

                                            if (lozinka != ponovljenalozinka) return alert("Lozinke moraju da se poklapaju");
                                            let adresastr = naziv + ", " + adresa + ", " + global.gradovi[grad].lokalniNaziv + "," + global.drzave[drzava].lokalniNaziv;
                                            Geocode.fromAddress(adresastr).then(
                                                (response) => {
                                                    const { lat, lng } = response.results[0].geometry.location;
                                                    lokacija = { lat, lng };
                                                    global.socket.emit("registracijaRestorana", {
                                                        naziv: naziv,
                                                        drzava: parseInt(drzava),
                                                        grad: parseInt(grad),
                                                        adresa: adresa,
                                                        broj_telefona: broj_telefona,
                                                        mejl: mejl.replace(/ /g, "").toLowerCase(),
                                                        lozinka: lozinka,
                                                        lokacija: lokacija
                                                    })
                                                    global.socket.once("odgovorNaRegistracijuRestorana", function (podaci) {
                                                        if (podaci.uspeh) {
                                                            alert("Uspeh");
                                                            window.location.reload(false);
                                                        }
                                                        else if (podaci.iskoriscenMejl) {
                                                            alert("Mejl je iskoriscen");
                                                        }
                                                        else {
                                                            alert("Greska");
                                                        }
                                                    });
                                                },
                                                (error) => {
                                                    if (error) {
                                                        alert("Ne mozemo da obradimo adresu. Kontaktirajte nas.");
                                                    }
                                                }
                                            );

                                        }} class="btnRegister" style={{ width: '100%', backgroundColor: 'black', color: 'white', borderRadius: 10 }} >Napravite nalog</button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div></div>);
    }
}