import Select from 'react-dropdown-select'
import React, { useState, useEffect, Component } from 'react';
import { isMobile } from "react-device-detect";

export class Tags extends Component {
    constructor(props) {
        super(props);
        let self = this;
        self.props = props;
        self.state = { tick: 0 };
        self.options = [{ indeks: 0, naziv: "Rostilj" }, { indeks: 1, naziv: "Burgeri" }, { indeks: 2, naziv: "Pica" }, { indeks: 3, naziv: "Italijanska hrana" },
        { indeks: 4, naziv: "Japanska hrana" }, { indeks: 5, naziv: "Piletina" }, { indeks: 6, naziv: "Kuvana jela" }, { indeks: 7, naziv: "Grcka hrana" },
        { indeks: 8, naziv: "Meksicka hrana" }, { indeks: 9, naziv: "Riba i plodovi mora" }, { indeks: 10, naziv: "Fit hrana" }, { indeks: 11, naziv: "Kineska hrana" },
        { indeks: 12, naziv: "Domaca kuhinja" }, { indeks: 13, naziv: "Kafa" }, { indeks: 14, naziv: "Peciva" }, { indeks: 15, naziv: "Indijska hrana" },
        { indeks: 16, naziv: "Palacinke" }, { indeks: 17, naziv: "Poslastice" }, { indeks: 18, naziv: "Sendvici" }, { indeks: 19, naziv: "Americka hrana" },
        { indeks: 20, naziv: "Arapska hrana" }, { indeks: 21, naziv: "Dorucak" }, { indeks: 22, naziv: "Posna hrana" }, { indeks: 23, naziv: "Brza hrana" }];
        let niz = props.parent?.oznake?.map(e => { return { value: e, label: self.options[e].naziv } });
        if (!niz) niz = [];
        console.log("USRANI NIZ");
        console.log(niz);
        self.selectedOptions = niz;
        //self.state = { selectedOptions: niz };
    }
    render() {
        let self = this;
        console.log("POSAFSVAO RENDER");
        let niz = self.props.parent.oznake.map(e => { return { value: e, label: self.options[e].naziv } });
        self.selectedOptions = niz;
        return (
            <>
                <div style={{ width: isMobile ? '80vw' : '50vh', marginBottom: '10vh' }} >
                    <Select placeholder="Selektujte kategorije hrane..." style={{ marginBottom: '2vh', borderRadius: 5 }} multi={true} options={self.options.map((item, index) => {
                        return { value: item.indeks, label: item.naziv }
                    })} values={self.selectedOptions} onChange={(values) => {
                        self.props.parent.oznake = values.map(e => e.value);
                        self.setState({ tick: self.state.tick + 1 })
                    }} />
                </div>
            </>
        );
    }
}