import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Container, Button } from "react-bootstrap";
import { OptionsGroup } from "./OptionsGroup";
import Compressor from 'compressorjs';

const IMAGEHOST = "https://guest.fra1.digitaloceanspaces.com";
export class Item extends Component {
    constructor(props) {
        super(props);
        this.naziv = this.props.props.naziv;
        this.cena = this.props.props.cena;
        this.opis = this.props.props.opis;
        this.grupeopcija = this.props.props.grupeopcija;
        this.loyalty = this.props.props.loyalty;
        this.slika = this.props.props.slika;
        this.identifikator = this.props.props.identifikator;
        this.generisiIdentifikator = props.generisiIdentifikator;
        this.slikainp = React.createRef();
        this.state = {
            id: this.props.id,
            prikazano: false,
            opisKlik: false,
            pomocniTekst: this.props.props.opis,
            brojac: 0
        }


    }
    updateOptionsGroup = (item, id) => {
        let pom = this.grupeopcija.slice()
        pom[id] = item;
        this.grupeopcija = pom;
        this.updateItem();

    }
    addOptionsGroup = () => {
        let pom = this.grupeopcija.slice()
        pom.push({ naziv: "Nova grupa opcija", min: 0, max: 0, opcije: [], identifikator: this.generisiIdentifikator() })
        this.grupeopcija = pom;
        this.updateItem()

    }
    removeOptionsGroup = (id) => {
        let pom = this.grupeopcija.slice();

        if (global.meni.nedostupno[this.grupeopcija[id].identifikator])
            delete global.meni.nedostupno[this.grupeopcija[id].identifikator];
        for (let o in this.grupeopcija[id].opcije) {
            let O = this.grupeopcija[id].opcije[o];
            if (global.meni.nedostupno[O.identifikator])
                delete global.meni.nedostupno[O.identifikator];
        }
        pom.splice(id, 1)
        this.grupeopcija = pom;
        //this.setState({ stavke: pom })
        this.updateItem();
    }

    updateItem = () => {
        this.props.updateitem({ id: this.props.id, naziv: this.naziv, cena: this.cena, kalorije: this.kalorije, opis: this.opis, slika: this.slika, loyalty: this.loyalty, grupeopcija: this.grupeopcija, identifikator: this.identifikator }, this.props.id)
    }

    render() {
        let self = this;
        return (
            (this.state.opisKlik == true) ? <Container
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', backgroundColor: 'whitesmoke', padding: 10 }}
            >
                {this.naziv}
                <input class="form-control" style={styles.opisInput}
                    value={this.state.pomocniTekst}
                    onChange={e => { this.setState({ pomocniTekst: e.target.value }) }}
                    placeholder="Unesite opis stavke (do 150 karaktera)"
                >
                </input>
                <Button
                    variant="success"
                    style={{ alignSelf: "center", width: "80%" }}
                    onClick={() => {
                        this.opis = this.state.pomocniTekst;
                        this.props.props.opis = this.state.pomocniTekst;
                        this.setState({ opisKlik: false })
                    }}>Sacuvaj</Button>
            </Container>
                :

                <Container {...this.props} style={styles.main0}>
                    <Container  {...this.props} style={styles.main}>
                        <img style={styles.image}
                            src={this.slika ? IMAGEHOST + this.slika : IMAGEHOST + "/slike/guestreact/dodajsliku.png"}
                            onClick={() => {
                                this.slikainp.current.click();
                            }}
                        ></img>
                        <input
                            style={{ display: "none" }}
                            accept="image/*"
                            type="file"
                            ref={this.slikainp}
                            onChange={(event) => {
                                const image = event.target.files[0];
                                new Compressor(image, {
                                    maxWidth: 540,
                                    maxHeight: 420,
                                    quality: 0.8,
                                    success: (compressedResult) => {
                                        global.socket.emit("uploadujSliku", { slika: compressedResult, lokacija: "stavke" });
                                        global.socket.once("odgovorNaUploadSlike", function (rez) {
                                            if (rez.uspeh) {
                                                self.slika = rez.url;
                                                self.updateItem();
                                            }
                                            else {
                                                alert("Molimo pokusajte ponovo");
                                            }
                                        })
                                    },
                                });
                            }}
                        />
                        <Container style={styles.atributi}>
                            <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <input class="form-control" style={styles.naziv} placeholder="Naziv" value={this.naziv}
                                    onChange={e => {
                                        this.naziv = e.target.value;
                                        this.updateItem()
                                    }}
                                ></input>
                                <img
                                    onClick={() => { this.props.removeItem(this.props.id) }}
                                    src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2F0%2F7%2Fe%2Fa%2F12074327311562940906milker_X_icon.svg.hi.png&f=1&nofb=1"
                                    style={{ width: '3vw', height: '3vw', marginLeft: 10, marginTop: 17 }}
                                />
                            </Container>
                            <Container style={styles.cenaContent} >
                                <a>Cena </a>
                                <input class="form-control" type="number" style={styles.cena} placeholder="Cena" value={this.cena}
                                    onChange={e => {
                                        this.cena = e.target.value;
                                        this.updateItem()
                                    }}

                                ></input>
                                <br></br>
                                <Button variant={this.loyalty <= -1 ? "outline-warning" : "warning"} style={styles.loyalty}
                                    onClick={() => {
                                        if (this.loyalty == -1) {
                                            this.loyalty = this.cena;
                                        }
                                        else {
                                            this.loyalty = -1
                                        }
                                        this.setState({ brojac: this.state.brojac + 1 });
                                        this.updateItem()
                                        //this.loyalty == -1 ? this.loyalty = this.cena : this.loyalty = -1
                                    }}
                                >{this.loyalty <= -1 ? "Dodaj u loyalty" : "Loyalty cena"}</Button>
                                {this.loyalty != -1 ?
                                    <input class="form-control" style={styles.cena} placeholder="Loyalty cena" value={this.loyalty}
                                        type="number"
                                        onChange={e => {
                                            this.loyalty = e.target.value;
                                            this.updateItem()
                                        }}

                                    ></input> : null
                                }
                            </Container>
                            <input class="form-control" style={styles.kalorije} placeholder="Kalorije" type="numerical" value={this.kalorije}
                                onChange={e => {
                                    this.kalorije = e.target.value;
                                    this.updateItem()
                                }}
                            ></input>
                            <Button variant="warning" style={styles.opis}
                                onClick={() => { this.setState({ opisKlik: !this.state.opisKlik }) }}
                            >Opis</Button>

                        </Container>
                    </Container>
                    {this.state.prikazano ? this.grupeopcija.map((item, index) => <OptionsGroup removeOptionsGroup={this.removeOptionsGroup} key={item.identifikator} updateoptionsgroup={this.updateOptionsGroup} props={item} index={index} generisiIdentifikator={self.generisiIdentifikator}></OptionsGroup>) : null}
                    <Button variant="light" style={{ width: '100%', marginBottom: 10, marginTop: 10, borderRadius: 10 }}
                        onClick={() => {
                            this.addOptionsGroup()
                            this.setState({ prikazano: true })
                        }}
                    >Nova grupa opcija</Button>
                    <Button variant={this.state.prikazano ? "dark" : "success"} style={{ width: '100%', marginBottom: 10, marginTop: 10, borderRadius: 10 }}
                        onClick={() => { this.setState({ prikazano: !this.state.prikazano }) }}
                    >{this.state.prikazano ? "Sakrij grupe opcija" : "Prikazi grupe opcija"}</Button>

                </Container >
        )
    }
}
const styles = {
    main0: {
        display: 'flex',
        flexDirection: "column", alignItems: 'center',
        alignSelf: 'center', width: '100%', marginTop: '3vh',
        backgroundColor: '#f5f5f5',
        borderRadius: 15,


    },
    main: {
        width: '90%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    image: {
        height: '100%',
        width: '39%',
        borderRadius: 10,
        padding: 3
    },
    text: {
        height: '100%',


    },
    atributi: {
        display: 'flex',
        alignSelf: 'stretch',
        marginLeft: 10,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    naziv: {
        width: '80%',
        textAlign: 'center',
        marginTop: '5%'
    },
    cena: {
        width: '40%',
        textAlign: 'center',
        margin: 5

    },
    cenaContent: {
        width: '90%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '5%'
    },
    loyalty: {
        width: '70%',
        margin: 5
    },
    kalorije: {
        width: '58%',
        marginTop: '5%'
    },
    opis: {
        width: '100%',
        marginTop: '5%',
        marginBottom: '1%',
        borderRadius: 10,
    },
    opisInput: {
        width: '80%', height: '30vh', borderRadius: 10, alignSelf: 'center', marginBottom: 10, textAlignVertical: 'top'
    }

}