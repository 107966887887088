import { Component, Platform } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Navbar1 } from "../../Components/Navbar1";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";


const platform = isMobile ? "Mobile" : "Desktop";
export class Prijava extends Component {
    constructor(props) {
        super(props);
        let self = this;
        self.state = { mejl: "", lozinka: "", modalStage: 0, mejlz: "", tokenz: "", lozinkaz1: "", lozinkaz2: "" };
    }
    render() {
        let self = this;
        return (
            <div style={styles.divG}>
                <Modal show={self.state.modalStage > 0} onHide={() => {
                    self.setState({ modalStage: 0 });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zaboravljena lozinka</Modal.Title>
                    </Modal.Header>
                    {
                        self.state.modalStage == 1 ? (<Modal.Body>
                            Unesite svoju mejl adresu adresu:
                            <input placeholder="Mejl" style={styles.input} className="form-control" value={self.state.mejlz} onChange={evt => { self.setState({ mejlz: evt.target.value }) }} type="text"></input>
                        </Modal.Body>) :
                            (
                                self.state.modalStage == 2 ? (
                                    <Modal.Body>
                                        Poslali smo Vam token za promenu lozinke na mejl koji ste uneli. Unesite ga u sledeće polje. Token važi 500 sekundi. :
                                        <input placeholder="Token" style={styles.input} className="form-control" value={self.state.tokenz} onChange={evt => { self.setState({ tokenz: evt.target.value }) }} type="text"></input>
                                    </Modal.Body>) : (
                                    <Modal.Body>
                                        Odaberite novu lozinku:
                                        <input placeholder="Nova lozinka" style={styles.input} className="form-control" value={self.state.lozinkaz1} onChange={evt => { self.setState({ lozinkaz1: evt.target.value }) }} type="password"></input>
                                        <input placeholder="Ponovite lozinku" style={styles.input} className="form-control" value={self.state.lozinkaz2} onChange={evt => { self.setState({ lozinkaz2: evt.target.value }) }} type="password"></input>
                                    </Modal.Body>)
                            )
                    }

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            if (self.state.modalStage == 3) {
                                self.setState({ lozinkaz1: "", lozinkaz2: "" });
                            }
                            else if (self.state.modalStage == 2) {
                                self.setState({ tokenz: "" });
                            }
                            else if (self.state.modalStage == 1) {
                                self.setState({ mejlz: "" });
                            }
                            self.setState({ modalStage: self.state.modalStage - 1 });
                        }}>
                            Nazad
                        </Button>
                        <Button variant="primary" onClick={
                            self.state.modalStage == 1 ? (
                                () => {
                                    global.socket.emit("restoranZaboravioLozinku", { mejl: self.state.mejlz.replace(/ /g, "").toLowerCase() });
                                    global.socket.once("odgovorNaRestoranovoZaboravljanjeLozinke", function (podaci) {
                                        if (podaci.uspeh) {
                                            self.setState({ modalStage: 2 });
                                        }
                                        else if (podaci.mejlLos) {
                                            alert("Uneli ste nepostojecu mejl adresu. ");
                                        }
                                        else {
                                            alert("neuspesno!");
                                        }
                                    })
                                }
                            ) : (
                                self.state.modalStage == 2 ? (() => {
                                    global.socket.emit("proveraTokenaZaMenjanjeSifre", { mejl: self.state.mejlz.replace(/ /g, "").toLowerCase(), token: self.state.tokenz });
                                    global.socket.once("odgovorNaProveruTokenaZaMenjanjeSifre", function (podaci) {
                                        if (podaci.uspeh) {
                                            self.setState({ modalStage: 3 });
                                        }
                                        else if (podaci.istekaoToken) {
                                            alert("Vas token je istekao. Molimo pokusajte ponovo. ");
                                        }
                                        else {
                                            alert("neuspesno!");
                                        }

                                    })

                                }) : (() => {
                                    if (self.state.lozinkaz1 == "") {
                                        alert("Unesite novu lozinku! ");
                                        return;
                                    }
                                    if (self.state.lozinkaz1 != self.state.lozinkaz2) {
                                        alert("Lozinke se ne poklapaju! ");
                                        return;
                                    }
                                    //regex
                                    global.socket.emit("promenaZaboravljeneLozinkeRestoran", { mejl: self.state.mejlz.replace(/ /g, "").toLowerCase(), token: self.state.tokenz, novaLozinka: self.state.lozinkaz1 });
                                    global.socket.once("odgovorNaPromenuZaboravljeneLozinkeRestoran", function (podaci) {
                                        if (podaci.uspeh) {
                                            alert("Uspesna promena lozinke! ")
                                            window.location.reload();
                                        }
                                        else if (podaci.istekaoToken) {
                                            alert("Vas token je istekao. Molimo pokusajte ponovo. ");
                                        }
                                        else {
                                            alert("neuspesno!");
                                        }
                                    })
                                })
                            )
                        }>
                            {self.state.modalStage == 3 ? "OK" : "Dalje"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Navbar1 />
                <div style={styles.div}>


                    <form style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <h3 className="register-heading" style={{ margin: 0, marginBottom: 20 }}>Prijavite se kao restoran</h3>

                        <div className="form-group">
                            <label>Mejl adresa</label>
                            <input value={self.state.mejl} style={styles.input} className="form-control" placeholder="Mejl" onChange={evt => { self.setState({ mejl: evt.target.value }) }} />
                        </div>

                        <div className="form-group">
                            <label>Lozinka</label>
                            <input value={self.state.lozinka} onChange={evt => { self.setState({ lozinka: evt.target.value }) }} style={styles.input} type="password" className="form-control" placeholder="Lozinka" />
                        </div>

                        <br />

                        <button className="btn btn-primary" style={{ backgroundColor: '#f5f5f5', borderColor: 'gray', width: '80%', color: 'black', borderWidth: 0, borderRadius: 10 }}
                            onClick={(e) => {
                                e.preventDefault();
                                let mejl = self.state.mejl;
                                let lozinka = self.state.lozinka;
                                global.socket.emit("prijavaRestorana", { mejl: mejl.replace(/ /g, "").toLowerCase(), lozinka: lozinka });
                                global.socket.once("odgovorNaPrijavuRestorana", function (podaci) {
                                    if (!podaci.uspeh) {
                                        alert("NEUSPESNA PRIJAVA");
                                    }
                                    else {
                                        alert("USPESNO", podaci._id + " " + podaci.kod_sesije)
                                        global.cookies.set('_id', podaci.restoran._id.toString(), { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                                        global.cookies.set('kod_sesije', podaci.restoran.kod_sesije.toString(), { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                                        window.location.reload(false);
                                        //global.functions.login();
                                        //location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/restoran/upravljanje");
                                    }
                                });
                            }}
                        >Prijavite se</button>
                        <p className="forgot-password text-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <a href="#" onClick={() => {
                                self.setState({ modalStage: 1 });
                            }}>Zaboravili ste lozinku?</a>
                        </p>

                    </form>

                </div>
                <Link style={{ fontSize: 19, borderRadius: 10 }} to="/registracija">Nemate nalog?</Link>

            </div>);
    }
}
/* 
 Korisnivcko ime: <input type="text" value={self.state.korisnibcko_ime} onChange={evt => { self.setState({ korisnickbo_ime: evt.target.value }) }}></input><br />
            Lozinka: <input type="password" value={self.state.lozinka} onChange={evt => { self.setState({ lozinka: evt.target.value }) }}></input><br />
            <button onClick={() => {
                let korisnicbko_ime = self.state.korisnickbo_ime;
                let lozinka = self.state.lozinka;
                global.socket.emit("prijavaRestorana", { korisnicbko_ime: korisnbicko_ime, lozinka: lozinka });
                global.socket.once("odgovorNaPrijavuRestorana", function (podaci) {
                    if (!podaci.uspeh) {
                        alert("NEUSPESNA PRIJAVA");
                    }
                    else {
                        global.cookies.set('korisnibcko_ime', podaci.korisnibcko_ime, { path: '/' });
                        global.cookies.set('kod_sesije', podaci.kod_sesije, { path: '/' });
                        global.functions.login();
                        //location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/restoran/upravljanje");
                    }
                });
            }}>Prijavi se</button><br />
            <Link to="/registracija">Napravi nalog</Link>
*/
const styles = {
    divG: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80vh', width: '100vw',
        flexDirection: 'column'
    },
    div: {
        width: isMobile ? '90vw' : "38vw",
        height: isMobile ? '' : "70vh",
        backgroundColor: 'white',
        padding: 30, borderRadius: 40, alignSelf: "center"
    },
    input: {
        borderRadius: 5
        , width: isMobile ? '80vw' : "25vw"
    },
    txt: {

    }
}