import { React, Component, Platform } from "react";
import { Button } from 'react-bootstrap'
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";

export class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = { staraSifra: "", novaSifra1: "", novaSifra2: "", modalShown: false, modalStage: 0, tokenz: "", lozinkaz1: "", lozinkaz2: "" }

    }
    proveriSifre = () => {
        if (this.state.novaSifra1 == this.state.novaSifra2)
            return true;

        return false;

    }
    render() {
        let self = this;
        return (
            <div>
                <Modal show={self.state.modalStage > 0} onHide={() => {

                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zaboravljena lozinka</Modal.Title>
                    </Modal.Header>
                    {
                        self.state.modalStage == 1 ? (
                            <Modal.Body>
                                Poslali smo Vam token za promenu lozinke na mejl koji ste uneli prilikom registracije. Unesite ga u sledeće polje. Ukoliko nemate pristup tom mejlu, kontaktirajte nas. Ukoliko odmah ne vidite mejl u sandučetu, sačekajte minut i pogledajte spam folder. Token važi 500 sekundi. :
                                <input placeholder="Token" style={styles.inputIzPrijave} className="form-control" value={self.state.tokenz} onChange={evt => { self.setState({ tokenz: evt.target.value }) }} type="text"></input>
                            </Modal.Body>) : (
                            <Modal.Body>
                                Odaberite novu lozinku:
                                <input placeholder="Nova lozinka" style={styles.inputIzPrijave} className="form-control" value={self.state.lozinkaz1} onChange={evt => { self.setState({ lozinkaz1: evt.target.value }) }} type="password"></input>
                                <input placeholder="Ponovite lozinku" style={styles.inputIzPrijave} className="form-control" value={self.state.lozinkaz2} onChange={evt => { self.setState({ lozinkaz2: evt.target.value }) }} type="password"></input>
                            </Modal.Body>)
                    }

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            if (self.state.modalStage == 2) {
                                self.setState({ lozinkaz1: "", lozinkaz2: "" });
                            }
                            else if (self.state.modalStage == 1) {
                                self.setState({ tokenz: "" });
                            }
                            self.setState({ modalStage: self.state.modalStage - 1 });
                        }}>
                            Nazad
                        </Button>
                        <Button variant="primary" onClick={
                            self.state.modalStage == 1 ? (
                                () => {

                                    global.socket.emit("proveraTokenaZaMenjanjeSifre", { token: self.state.tokenz });
                                    global.socket.once("odgovorNaProveruTokenaZaMenjanjeSifre", function (podaci) {
                                        if (podaci.uspeh) {
                                            self.setState({ modalStage: 3 });
                                        }
                                        else if (podaci.istekaoToken) {
                                            alert("Vas token je istekao. Molimo pokusajte ponovo. ");
                                        }
                                        else {
                                            alert("neuspesno!");
                                        }

                                    })
                                }
                            ) : (() => {

                                if (self.state.lozinkaz1 == "") {
                                    alert("Unesite novu lozinku! ");
                                    return;
                                }
                                if (self.state.lozinkaz1 != self.state.lozinkaz2) {
                                    alert("Lozinke se ne poklapaju! ");
                                    return;
                                }
                                //regex
                                global.socket.emit("promenaZaboravljeneLozinkeRestoran", { token: self.state.tokenz, novaLozinka: self.state.lozinkaz1 });
                                global.socket.once("odgovorNaPromenuZaboravljeneLozinkeRestoran", function (podaci) {
                                    if (podaci.uspeh) {
                                        //alert("Uspesna promena lozinke! ")
                                        self.novasifra = self.state.lozinkaz1;
                                        self.setState({ modalStage: 0, modalShown: true });
                                        //PITANJE DA IZLOGUJE SVE
                                        //window.location.reload();
                                    }
                                    else if (podaci.istekaoToken) {
                                        alert("Vas token je istekao. Molimo pokusajte ponovo. ");
                                    }
                                    else {
                                        alert("neuspesno!");
                                    }
                                })
                            })
                        }>
                            {self.state.modalStage == 2 ? "OK" : "Dalje"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={self.state.modalShown} onHide={() => {
                    self.novasifra = "";
                    self.setState({ modalShown: false });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Uspesno promenjena lozinka</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Da li želite da odjavite ostale uređaje prijavljene na ovaj nalog?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            self.novasifra = "";
                            self.setState({ modalShown: false });
                        }}>
                            Ne
                        </Button>
                        <Button variant="primary" onClick={() => {
                            global.socket.emit("odjaviOstale", { kod_sesije: global.restoran.kod_sesije, lozinka: self.novasifra });
                            self.setState({ modalShown: false });
                            global.socket.once("odgovorNaOdjavuOstalih", function (podaci) {
                                if (podaci.uspeh) {
                                    alert("Uspesna odjava ostalih uređaja. ");
                                }
                                else {
                                    alert("Neuspesna odjava ostalih uređaja. ");
                                }
                            })
                        }}>
                            Da
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div style={styles.container}>
                    <input value={this.state.staraSifra} style={styles.input} onChange={(e) => { this.setState({ staraSifra: e.target.value }) }} type="password" className="form-control" placeholder="Unesite trenutnu lozinku"></input>
                    <input value={this.state.novaSifra1} style={styles.input} onChange={(e) => { this.setState({ novaSifra1: e.target.value }) }} type="password" className="form-control" placeholder="Unesite novu lozinku"></input>
                    <input value={this.state.novaSifra2} style={styles.input} onChange={(e) => { this.setState({ novaSifra2: e.target.value }) }} type="password" className="form-control" placeholder="Potvrdite novu lozinku"></input>

                    <Button variant="outline-success"
                        style={styles.save}
                        onClick={() => {
                            if (self.proveriSifre()) {
                                global.socket.emit("zahtevZaPromenuLozinke", { lozinka: self.state.staraSifra, novaLozinka: self.state.novaSifra1 });
                                global.socket.once("odgovorNaZahtevZaPromenuLozinke", function (podaci) {
                                    if (podaci.uspeh) {
                                        self.novasifra = self.state.novaSifra1;
                                        self.setState({ staraSifra: "", novaSifra1: "", novaSifra2: "", modalShown: true });
                                    }
                                    else if (podaci.losaLozinka) {
                                        alert("Lozinka koju ste uneli nije tacna. ")
                                    }
                                    else {
                                        alert("Greska! Molimo pokusajte ponovo za 5 minuta. ");
                                    }
                                });
                            }
                            else {
                                alert("Nove lozinke se ne poklapaju! ");
                            }

                        }}
                    >
                        Promenite lozinku
                    </Button>
                    <Button variant="danger" style={styles.save} onClick={() => {
                        global.socket.emit("restoranZaboravioLozinku");
                        self.setState({ modalStage: 1 });
                    }}>Zaboravljena lozinka?</Button>
                </div>
            </div>)
    }

}
const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10
    },
    input: {
        width: isMobile ? '90vw' : '40vw',
        height: isMobile ? '6vh' : '8vh',
        marginBottom: 10, borderRadius: 10
    },
    inputIzPrijave: {
        borderRadius: 5
        , width: isMobile ? '80vw' : "25vw"
    },
    save: {
        borderRadius: 50, width: isMobile ? '90vw' : '40vw', height: isMobile ? '8vh' : '8vh', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 20, marginBottom: 10
    }
}