import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';


export default class QRRender extends React.PureComponent {
    constructor(props) {
        super(props);
        let self = this;
        self.url = props.url;
        this.props = props;

    }
    render() {
        let self = this;
        self.opcija = this.props.opcija;
        console.log(self.opcija)
        return (<div style={{ margin: 10, background: "#F9F63F" }}>
            <div style={{ fontSize: 35, color: "#111111", fontWeight: "bold", textAlign: "center", fontFamily: "sans-serif" }}>{
                self.opcija == 1 ? "Mrzi Vas da čekate u redu? Skenirajte kod!" :
                    self.opcija == 2 ? "Naručite brže i jeftinije uz Guest." :
                        self.opcija == 3 ? "Čekanje u redu Vam nije omiljena stvar? " : "4"
            }</div>
            <QRCode
                value={self.url}
                size={400}
                quietZone={20}
                bgColor={"#F9F63F"}
                fgColor={"#000000"}
                logoWidth={50}
                logoHeight={50}
                logoOpacity={0.5}
            />
        </div>
        );
    }
}