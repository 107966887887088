import React, { Component } from "react";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'

export class Navbar1 extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Navbar style={{ width: '100%', marginBottom: 10, position: "sticky", top: 0 }} collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container style={{ flex: 1 }}>
          <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: "baseline" }}>
            <img src="https://img.onl/z27Hf2" style={{ width: '8vh', height: '7vh', borderRadius: 5, marginRight: "1vw" }}></img>
            <Navbar.Brand href="/" style={{ marginTop: 15, alignSelf: 'center' }} >Guest</Navbar.Brand>

          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/restoran/">O nama</Nav.Link>
              <Nav.Link href="/restoran/pricing">Cene</Nav.Link>

            </Nav>
            <Nav>
              <Nav.Link href="/restoran/registracija"> Registrujte svoj restoran</Nav.Link>
              <Nav.Link href="/restoran/prijava">Prijavite se</Nav.Link>

              <Nav.Link eventKey={2} href="/restoran/contact">
                Kontaktirajte nas!
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}
const styles = {
  div: {


  },
  link: {

  },
  txt: {

  }
}