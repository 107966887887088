import { React, Component, Platform } from "react";
import { Button, Container } from "react-bootstrap"
import { isMobile } from "react-device-detect";


export class TrenutnaPorudzbina extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    prikazi = () => {
        this.props.prikazi(190)
    }
    otkazi = () => {
        this.props.otkazi(this.props.narudzbina._id)
        this.prikazi()
    }
    spremi = () => {
        this.props.spremi(this.props.narudzbina._id);
    }
    zavrsi = () => {
        this.props.zavrsi(this.props.narudzbina._id)
        this.prikazi()

    }
    stampaj = () => {
        let stringvreme;
        let otvorena = this.props.narudzbina;
        if (otvorena.vremesakupljanja == null) {
            try {
                var h = new Date(otvorena.vreme_narudzbine + otvorena.putovanje.sekunde * 1000).getHours();
                var m = new Date(otvorena.vreme_narudzbine + otvorena.putovanje.sekunde * 1000).getMinutes();
                h = (h < 10) ? '0' + h : h;
                m = (m < 10) ? '0' + m : m;
                var output = h + ':' + m;
                stringvreme = "Što pre (~" + output + ")";
            }
            catch {
                stringvreme = "Što pre ";
            }
        }
        else {
            stringvreme = "Vreme dolaska: " + otvorena.vremesakupljanja;
        }
        let mywindow = window.open('', 'PRINT', 'height=400,width=600');
        let tabela = document.createElement("table");
        let thd = document.createElement("thead");
        let thtr = document.createElement("tr");
        let th1 = document.createElement("th");
        th1.className = "quantity";
        th1.innerText = "Q.";
        thtr.appendChild(th1);
        let th2 = document.createElement("th");
        th2.className = "description";
        th2.innerText = "Naziv";
        thtr.appendChild(th2);
        let th3 = document.createElement("th");
        th3.className = "price";
        th3.innerText = "Cena";
        thtr.appendChild(th3);
        thd.appendChild(thtr);
        tabela.appendChild(thd);
        let ukupnacenasvega = 0;
        let tbd = document.createElement("tbody");
        for (let i in otvorena.korpa) {
            ukupnacenasvega += otvorena.korpa[i].ukupnacena;
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = otvorena.korpa[i].kolicina;
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            let nazivstring = otvorena.korpa[i].naziv;
            for (let j in otvorena.korpa[i].grupeopcija) {
                let GO = otvorena.korpa[i].grupeopcija[j];
                nazivstring += "\n" + GO.naziv + ": ";
                for (let o in GO.opcije) {
                    let O = GO.opcije[o];
                    nazivstring += "\n• " + (O.kolicina != 1 ? (O.kolicina + "x ") : "") + O.naziv;
                }
                if (GO.opcije.length == 0) {
                    nazivstring += "\n--NISTA--";
                }
            }
            nazivstring += (otvorena.korpa[i].napomene) ? ("\nNapomene: \n" + otvorena.korpa[i].napomene) : ("");
            td2.innerText = nazivstring;
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = otvorena.korpa[i].ukupnacena;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);
        }

        if (otvorena.brojMesta == -1) {
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = "*";
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            td2.innerText = "Dostava";
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = otvorena.cenadostave;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);
        }


        if (otvorena.kupon) {
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = 1;
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            let nazivstring = otvorena.kupon.tekst;
            td2.innerText = nazivstring;
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = "-" + otvorena.kupon.vrednost;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);

            ukupnacenasvega -= otvorena.kupon.vrednost;
            if (ukupnacenasvega < 0) {
                ukupnacenasvega = 0;
            }
        }
        let ukupnotr = document.createElement("tr");
        let td1u = document.createElement("td");
        td1u.className = "quantity";
        td1u.innerText = "";
        ukupnotr.appendChild(td1u);
        let td2u = document.createElement("td");
        td2u.className = "description";
        td2u.innerText = "UKUPNO";
        ukupnotr.appendChild(td2u);
        let td3u = document.createElement("td");
        td3u.className = "price";
        td3u.innerText = ukupnacenasvega + (otvorena.brojMesta == -1 ? otvorena.cenadostave : 0);
        ukupnotr.appendChild(td3u);

        tbd.appendChild(ukupnotr);
        tabela.appendChild(tbd);

        mywindow.document.write(`<!DOCTYPE html>
<html lang="en">

<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<meta http-equiv="X-UA-Compatible" content="ie=edge">
<link rel="stylesheet" href="style.css">
<title>Racun</title>
<style>
    * {
        font-size: 13px;
        font-family: 'Helvetica';
    }

    td,
    th,
    tr,
    table {
        word-wrap: break-word;
        border-top: 1px solid black;
        border-collapse: collapse;
    }

    td.description,
    th.description {
        width: 95px;
        max-width: 95px;
    }

    td.quantity,
    th.quantity {
        width: 20px;
        max-width: 20px;
        word-break: break-all;
    }

    td.price,
    th.price {
        width: 40px;
        max-width: 40px;
        word-break: break-all;
    }

    .centered {
        text-align: center;
        align-content: center;
    }

    .ticket {
        width: 155px;
        max-width: 155px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

    @media print {

        .hidden-print,
        .hidden-print * {
            display: none !important;
        }
    }
</style>
</head>

<body>
<div class="ticket">
    <p class="centered">NARUDZBINA
        <br>`+ "Sifra: " + otvorena.sifra + `
        <br>`+ stringvreme + `
        <br>`+ (otvorena.brojMesta == 0 ? "Za poneti" : (otvorena.brojMesta == -1 ? ("Dostava na adresu " + otvorena.adresa) : "Za ovde")) + `
    </p>
    `+
            tabela.outerHTML
            + `
</div>
</body>

</html>`);

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid) {
            //window.location = 'http://android.davidwalsh.name';
            mywindow.print();
            //mywindow.print();
            //mywindow.print();
            //mywindow.close();
        }
        else {
            mywindow.print();
            mywindow.close();
        }

        return true;
    }

    prihvati = () => {
        this.props.prihvati(this.props.narudzbina._id)
        this.prikazi()

    }
    odbij = () => {
        this.props.odbij(this.props.narudzbina._id)
        this.prikazi()

    }
    nadjiCenu = () => {
        let ukupno = 0;
        for (let i = 0; i < this.props.narudzbina.korpa.length; i++) {
            ukupno += this.props.narudzbina.korpa[i].ukupnacena;
        }
        if (this.props.narudzbina.kupon) {
            ukupno -= this.props.narudzbina.kupon.vrednost;
            if (ukupno < 0) {
                ukupno = 0;
            }
        }
        return (ukupno);
    }
    render() {
        let procenjenovremedolaska;
        if (this.props.narudzbina.putovanje) {
            let datum = new Date(this.props.narudzbina.vreme_narudzbine + 1000 * this.props.narudzbina.putovanje.sekunde);
            let sati = datum.getHours();
            let minuti = datum.getMinutes();
            procenjenovremedolaska = sati + ":" + (minuti < 10 ? ("0" + minuti) : minuti);
        }
        else {
            procenjenovremedolaska = null;
        }
        let vremest = this.props.narudzbina.korisnik ? (this.props.narudzbina.vremesakupljanja ? ("Vreme sakupljanja: " + this.props.narudzbina.vremesakupljanja) : (procenjenovremedolaska ? ("Procenjeno vreme dolaska :" + procenjenovremedolaska) : "Što pre")) : (this.props.narudzbina.vremesakupljanja ? ("Vreme sakupljanja: " + this.props.narudzbina.vremesakupljanja + " (korisnik nije registrovan)") : "Što pre (korisnik nije registrovan)");
        return (
            this.props.poruka ? <div style={{ backgroundColor: 'black', height: 100, width: 100 }}></div> : <Container style={styles.container}>
                <p style={{ marginTop: 5, fontFamily: 'sans-serif', alignSelf: 'center' }}>{(this.props.narudzbina.brojMesta == 0 ? "Za poneti, " : (this.props.narudzbina.brojMesta == -1 ? "Dostava, " : "Za ovde, ")) + vremest}</p>
                {this.props.narudzbina.brojMesta != -1 ||
                    <a style={{ display: "block", textAlign: "center" }} href={"https://www.google.com/maps/place/" + this.props.narudzbina.adresa.replaceAll("/", "%2F") + ", Beograd, Srbija"} target="_blank">{this.props.narudzbina.adresa}</a>}
                <p style={{ fontFamily: 'sans-serif', alignSelf: 'center' }}>Šifra: {this.props.narudzbina.sifra}</p>
                {
                    this.props.narudzbina.korpa.map((item, index) =>
                        <Container style={{ marginTop: 5, fontFamily: "sans-serif", backgroundColor: '#e9e9e9', borderRadius: 10, fontSize: 14 }}>
                            {item.naziv + " | " + (item.cena ? (item.cena + " rsd") : (item.loyalty + " LP"))}
                            <div style={styles.linija}></div>
                            {item.grupeopcija.map((grupeop, index) =>
                                <Container style={{ backgroundColor: 'white', marginTop: 20, borderRadius: 5, fontFamily: 'sans-serif' }}>
                                    {grupeop.naziv}
                                    <div style={styles.linija2}></div>
                                    {grupeop.opcije.map((opcije, index) =>
                                        <Container>
                                            {opcije.naziv + " * " + opcije.kolicina} {opcije.cena > 0 ? (" | " + opcije.cena * opcije.kolicina + " rsd") : null}

                                        </Container>)}
                                </Container>)}
                            {
                                item.napomene ? <Container style={{ backgroundColor: 'white', marginTop: 20, borderRadius: 5, fontFamily: 'sans-serif' }}>
                                    {"Napomene"}
                                    <div style={styles.linija2}></div>
                                    {item.napomene}
                                </Container> : null
                            }
                            {"Ukupna cena : " + item.ukupnacena + " |  rsd"}
                        </Container>
                    )
                }
                {
                    (this.props.narudzbina.kupon ? <Container style={{ marginTop: 5, fontFamily: "sans-serif", backgroundColor: '#e9e9e9', borderRadius: 10, fontSize: 16, padding: 10 }}>
                        {this.props.narudzbina.kupon.tekst + " | -" + this.props.narudzbina.kupon.vrednost + " rsd"}
                    </Container> : null)
                }
                {
                    this.props.narudzbina.brojMesta != -1 ||
                    <Container style={{ marginTop: 5, fontFamily: "sans-serif", backgroundColor: '#e9e9e9', borderRadius: 10, fontSize: 16, padding: 10 }}>
                        {"Dostava " + this.props.narudzbina.cenadostave + " rsd"}
                    </Container>
                }
                <p style={{ marginTop: 10, fontFamily: 'sans-serif', alignSelf: 'center', textAlign: "center" }}>{"Cena narudzbine : " + (this.nadjiCenu() + (this.props.narudzbina.brojMesta == -1 ? this.props.narudzbina.cenadostave : 0) + " rsd")}</p>
                {
                    this.props.narudzbina.status == "neprihvacena" ? <div style={styles.div1}><Button variant="success" style={{}}
                        onClick={this.prihvati}
                    >Prihvati</Button>
                        <Button variant="danger" style={{ marginTop: 10 }}
                            onClick={this.odbij}
                        >Odbij</Button></div> :
                        <div style={styles.div1}>

                            <Button variant="success" style={{}}
                                onClick={this.props.narudzbina.status == "priprema" ? this.spremi : this.zavrsi}
                            >{this.props.narudzbina.status == "priprema" ? "Spremi" : "Zavrsi"}</Button>
                            <Button variant="danger" style={{ marginTop: 10 }}
                                onClick={this.otkazi}
                            >Otkazi</Button>
                        </div>

                }

                {
                    isMobile ? <Button variant="light" style={{ marginTop: 10 }}
                        onClick={this.prikazi}
                    >Nazad</Button> : null
                }
                <Button variant="danger" style={{ marginTop: 20 }}
                    onClick={this.stampaj}
                >Stampaj</Button>

            </Container >)
    }
}
const styles = {
    container: {
        backgroundColor: "#f5f5f5", height: 'auto', width: '100%', display: 'flex', flexDirection: 'column', maxHeight: "100vh"
    },
    linija: {
        height: 0.5, width: '100%', backgroundColor: "black", margin: 5
    },
    linija2: {
        height: 0.5, width: '100%', backgroundColor: "white", margin: 5
    },
    div1: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    }
}
