import React, { Component } from "react";
import { Navbar, Nav, Container, NavDropdown, NavLink } from 'react-bootstrap'
import { isMobile } from "react-device-detect";

import '../Nav.css'
export class Navbar2 extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: ""
    }
    const isActive = {
      fontWeight: "bold",
      backgroundColor: "lightgrey"
    };
  }
  render() {

    return (
      <Navbar style={{ width: '100vw', marginBottom: 10, top: 0, borderBottomWidth: 10 }} collapseOnSelect expand="lg" bg="light" variant="light">
        <Container style={{ flex: 1 }}>
          <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: "baseline", alignSelf: "center" }}>
            <Navbar.Brand href="/restoran/narudzbine" style={{ ...styles.txt, ...{ fontSize: 30, backgroundColor: "#252525", padding: 7, borderRadius: 5, fontFamily: "sans-serif", color: 'white' } }} >Narudzbine</Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ width: '70%' }}>
            <Nav className="me-auto" style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
              {!isMobile ? <NavLink
                href="/restoran/meni"
                style={styles.txt}>Meni</NavLink> : null}
              <NavLink href="/restoran/upravljanje" style={styles.txt}>Upravljanje</NavLink>
              <NavLink href="/restoran/statistika" style={styles.txt}>Statistika</NavLink>
              <NavLink href="/restoran/statistika" style={{
                fontSize: isMobile ? 20 : 30, color: "red", borderRadius: 10
              }}
                onClick={() => {
                  global.socket.emit("odjavljivanje");
                  global.socket.once("odgovorNaOdjavljivanje", function (podaci) {
                    if (podaci.uspeh) {
                      //alert("Uspesno odjavljivanje");
                    }
                    else {
                      //alert("Neuspesno odjavljivanje");
                      //Mozda mogucnost za nasilno odjavljivanje
                    }
                  });
                }}
              >

                Odjavi se</NavLink>

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}
const styles = {
  div: {


  },
  link: {
    fontSize: 10
  },
  txt: {
    fontSize: isMobile ? 20 : 30

  }
}