import { React, Component, Platform } from "react";
import { Button, Container } from "react-bootstrap"
import { isMobile } from "react-device-detect";


export class PrihvacenaNarudzbina extends Component {
    constructor(props) {
        super(props);
        this.cena = this.props.props.cena;
        this.sifra = this.props.props.sifra;
        this._id = this.props.props._id;
        this.status = this.props.props.status;
        this.cenadostave = this.props.props.brojMesta == -1 ? this.props.props.cenadostave : null;
        //NIJE SVUDA PROCENJENO VREME DOLASKA, NEGDE JE ZELJENO VREME
        /*if (this.props.props.putovanje) {
            let datum = new Date(this.props.props.vreme_narudzbine + 1000 * this.props.props.putovanje.sekunde);
            let sati = datum.getHours();
            let minuti = datum.getMinutes();
            this.procenjenovremedolaska = sati + ":" + (minuti < 10 ? ("0" + minuti) : minuti);
        }
        else {
            this.procenjenovremedolaska = "Što pre";
        }*/

        //this.state = { _id: this.props.props._id }
    }
    prikazi = async (c) => {
        if (c == 0) {
            await this.props.ucitajporuke(this._id);
        }
        this.props.prikazi(this._id, c)

    }
    otkazi = () => {
        this.props.otkazi(this._id)
    }
    spremi = () => {
        this.props.spremi(this._id)
    }
    zavrsi = () => {
        this.props.zavrsi(this._id)
    }
    nadjiCenu = (narudzbina) => {
        let ukupno = 0;
        for (let i = 0; i < narudzbina.korpa.length; i++) {
            ukupno += narudzbina.korpa[i].ukupnacena;
        }
        if (narudzbina.kupon) {
            ukupno -= narudzbina.kupon.vrednost;
            if (ukupno < 0) {
                ukupno = 0;
            }
        }
        return Math.round(ukupno * 100) / 100;
    }
    render() {
        let self = this;
        let narudzbina = this.props.props;
        let procenjenovremedolaska;
        if (narudzbina.putovanje) {
            let datum = new Date(narudzbina.vreme_narudzbine + 1000 * narudzbina.putovanje.sekunde);
            let sati = datum.getHours();
            let minuti = datum.getMinutes();
            procenjenovremedolaska = sati + ":" + (minuti < 10 ? ("0" + minuti) : minuti);
        }
        else {
            procenjenovremedolaska = null;
        }
        let vremest = narudzbina.korisnik ? (narudzbina.vremesakupljanja ? ("Vreme sakupljanja: " + narudzbina.vremesakupljanja) : ("Što pre" + (procenjenovremedolaska ? (" (~" + procenjenovremedolaska + ")") : ""))) : (narudzbina.vremesakupljanja ? ("Vreme sakupljanja: " + narudzbina.vremesakupljanja + " (korisnik nije registrovan)") : "Što pre (korisnik nije registrovan)");
        let vremnar = new Date(narudzbina.vreme_narudzbine);
        return (
            <Container style={{ ...styles.container, paddingBottom: 5 }}

            >

                <Container style={styles.sadrzaj}
                    onClick={() => { /*this.prikazi(1)*/ }}
                >
                    <div style={{ fontFamily: 'sans-serif', alignSelf: 'center' }}>Šifra: {narudzbina.sifra}, {(narudzbina.brojMesta == 0 ? "Za poneti" : (narudzbina.brojMesta == -1 ? "Dostava" : "Za ovde"))} <a style={{ marginLeft: 10, textDecoration: "underline" }} onClick={() => {
                        self.props.stampaj(narudzbina);
                    }}>Štampaj</a></div>
                    <div style={{ fontFamily: 'sans-serif', alignSelf: 'center', fontSize: '20px', fontWeight: "bold", textAlign: "center" }}>{vremest}  {(narudzbina.brojac || narudzbina.brojac === 0) ? <span style={{ whiteSpace: "nowrap" }}><img style={{ marginTop: -3 }} width="22px" height="22px" src="https://fontawesomeicons.com/lib/svg/clock-bold.svg" /> {narudzbina.brojac}min</span> : ""}</div>
                    {narudzbina.brojMesta != -1 ||
                        <a style={{ display: "block", textAlign: "center" }} href={"https://www.google.com/maps/place/" + narudzbina.adresa.replaceAll("/", "%2F") + ", Beograd, Srbija"} target="_blank">{narudzbina.adresa}</a>}
                    {!narudzbina.broj_telefona ||
                        <a style={{ display: "block", textAlign: "center" }} href={"tel://" + narudzbina.broj_telefona} target="_blank">{narudzbina.broj_telefona}</a>}

                    {
                        narudzbina.korpa.sort((a, b) => {
                            return b.ukupnacena - a.ukupnacena;
                        }).map((item, index) =>
                            <Container key={index} style={{ fontFamily: "sans-serif", backgroundColor: 'white', fontSize: 14 }}>
                                <b>{(item.kolicina > 1) && (item.kolicina + "x ")} {item.naziv}</b> {" | " + (item.cena ? (Math.round(item.ukupnacena * 100) / 100 + " rsd") : (item.loyalty + " LP + " + Math.round(item.ukupnacena * 100) / 100 + " rsd"))}
                                <div style={styles.linija}></div>
                                {item.grupeopcija.map((grupeop, index) =>
                                    <div key={index} style={{ backgroundColor: 'white', fontFamily: 'sans-serif' }}>
                                        <hr style={{ margin: 0 }}></hr>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ width: "100%" }}>
                                                {grupeop.opcije.map((opcije, index) =>
                                                    <div key={index} style={{ lineHeight: 1.1 }}>
                                                        {(opcije.kolicina > 1 ? (opcije.kolicina + " x ") : "") + opcije.naziv} {opcije.cena > 0 ? (" + " + opcije.cena * opcije.kolicina + " rsd") : null}
                                                    </div>)}
                                            </div>
                                            <div><em style={{ whiteSpace: "nowrap" }}>{grupeop.naziv}</em></div>
                                        </div>
                                    </div>)}
                                {
                                    item.napomene ? <Container style={{ backgroundColor: 'white', fontFamily: 'sans-serif' }}>
                                        {"Napomene"}
                                        {item.napomene}
                                    </Container> : null
                                }
                                <hr style={{ margin: 0 }}></hr>
                            </Container>
                        )
                    }
                    {
                        (narudzbina.kupon ? <Container style={{ fontFamily: "sans-serif", backgroundColor: 'white', fontSize: 16, padding: 10 }}>
                            {narudzbina.kupon.tekst + " | -" + narudzbina.kupon.vrednost + " rsd"}
                        </Container> : null)
                    }
                    {
                        narudzbina.brojMesta != -1 ||
                        <Container style={{ fontFamily: "sans-serif", backgroundColor: 'white', borderRadius: 10, fontSize: 16, padding: 10 }}>
                            {"Dostava " + narudzbina.cenadostave + " rsd"}
                        </Container>
                    }
                    <p style={{ marginTop: 10, fontFamily: 'sans-serif', alignSelf: 'center', textAlign: "center" }}>{"Cena narudzbine : " + (this.nadjiCenu(narudzbina) + (narudzbina.brojMesta == -1 ? narudzbina.cenadostave : 0) + " rsd")}</p>
                    {/*<a style={styles.txt}>Cena : {this.cena} {this.cenadostave === undefined || this.cenadostave === null || ("+ " + this.cenadostave + " (dostava)")}</a>
                    <a style={styles.txt}>{this.props.props.korisnik ? (this.props.props.vremesakupljanja ? ("Vreme dolaska: " + this.props.props.vremesakupljanja) : ("Procenjeno vreme dolaska :" + this.procenjenovremedolaska)) : (this.props.props.vremesakupljanja ? ("Vreme: " + this.props.props.vremesakupljanja + " (neregistrovan)") : "Neregistrovan korisnik")}</a>
                    <a style={styles.txt}>Sifra : {this.sifra}</a>*/}


                </Container>

                <Container style={styles.btnContainer}>
                    <Button variant="danger" style={styles.otkazi}
                        onClick={this.otkazi}
                    >
                        Otkazi
                    </Button>
                    <Button variant={this.props.props.poruke.restoranseen ? "light" : "primary"} style={styles.cet}
                        onClick={() => {
                            global.socket.emit("sinuj", { _id: self._id });
                            global.socket.once("uspesnoSinovao", function () {
                                self.props.props.poruke.restoranseen = true;
                            })
                            this.prikazi(0)
                        }}

                    >
                        Poruke {this.props.props.poruke.restoranseen ? null : "*"}

                    </Button>
                    <Button variant="success" style={styles.zavrsi}
                        onClick={(this.status == "priprema") ? this.spremi : this.zavrsi}
                    >
                        {(this.status == "priprema") ? "Spremi" : "Zavrsi"}
                    </Button>
                </Container>
            </Container>
        )
    }
}
const styles = {
    container: {
        display: 'flex',
        width: '90%',
        //height: isMobile ? '13vh' : '15vh',
        marginTop: 10
        , backgroundColor: '#fcf56a',
        borderRadius: 5,

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sadrzaj: {
        display: 'flex',
        flexDirection: 'column',
    },
    zavrsi: {
        width: '30%', height: '80%',
        display: 'flex',
        textAlign: 'center', justifyContent: 'center', alignItems: 'center',
        padding: 2

    },
    otkazi: {
        width: '30%', height: '80%',
        display: 'flex',
        textAlign: 'center', justifyContent: 'center', alignItems: 'center',
        padding: 2

    },
    cet: {
        width: '30%', height: '80%',
        display: 'flex',
        textAlign: 'center', justifyContent: 'center', alignItems: 'center',
        padding: 2
    },
    btnContainer: { alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', marginTop: 0 },
    txt: {
        fontSize: 16, fontFamily: 'sans-serif',
    }
}