import React, { Component } from "react";
import { Container, Button } from "react-bootstrap";
import { Option } from "./Option";
export class OptionsGroup extends React.Component {
    constructor(props) {
        super(props);
        this.min = props.props.min;
        this.max = props.props.max;
        this.naziv = props.props.naziv;
        this.options = props.props.opcije;
        this.identifikator = props.props.identifikator;
        this.generisiIdentifikator = props.generisiIdentifikator;
        this.state = { prikazano: false, index: props.index }
    }
    updateOptionsGroup = () => {
        this.props.updateoptionsgroup({ naziv: this.naziv, min: this.min, max: this.max, opcije: this.options, identifikator: this.identifikator }, this.state.index)
    }
    addOption = () => {
        let pom = this.options.slice()
        pom.push({ naziv: "Nova opcija", cena: 0, slika: "", maxkolicina: 1, racunajSamoJednom: true, identifikator: this.generisiIdentifikator() })
        this.options = pom;
        this.updateOptionsGroup()
    }
    updateOption = (item, index) => {
        let ind = 0;
        for (let i in this.options) {
            if (this.options[i].identifikator == index) {
                ind = i;
            }
        }
        index = ind;
        let pom = this.options.slice();
        pom[index] = item;
        this.options = pom;
        this.updateOptionsGroup()
    }
    removeOption = (id) => {
        let ind = 0;
        for (let i in this.options) {
            if (this.options[i].identifikator == id) {
                ind = i;
            }
        }

        if (global.meni.nedostupno[this.options[ind].identifikator]) {
            delete global.meni.nedostupno[this.options[ind].identifikator];
        }
        let pom = this.options.slice();
        pom.splice(ind, 1)
        this.options = pom;
        //this.setState({ stavke: pom })
        this.updateOptionsGroup();
    }
    render() {
        let self = this;
        return (
            <Container {...this.props} style={styles.container}>
                <Container style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <input class="form-control" style={styles.naziv} value={this.naziv}

                        onChange={e => {
                            this.naziv = e.target.value;
                            this.updateOptionsGroup()
                        }}
                    ></input>
                    <img
                        onClick={() => { this.props.removeOptionsGroup(this.props.index) }}
                        src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2F0%2F7%2Fe%2Fa%2F12074327311562940906milker_X_icon.svg.hi.png&f=1&nofb=1"
                        style={{ width: '3vw', height: '3vw', alignSelf: "flex-start", marginTop: 5 }}
                    />
                </Container>
                <Container style={{ display: "flex", flexDirection: "row", margin: 10, alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
                    <input type="number" style={styles.minimaks} class="form-control" placeholder="Minimalni broj opcija" value={this.min}
                        onChange={e => {
                            if (e.target.value >= 0 && e.target.value <= this.max) { this.min = e.target.value }
                            this.updateOptionsGroup()
                        }}
                    >
                    </input>
                    <input

                        onChange={e => {
                            if (e.target.value <= this.options.reduce((p, o) => p + parseInt(o.racunajSamoJednom ? 1 : o.maxkolicina), 0) && e.target.value >= this.min) { this.max = e.target.value };

                            this.updateOptionsGroup();
                        }}
                        type="number" class="form-control" style={styles.minimaks} placeholder="Maksimalni broj opcija" value={this.max}></input>
                </Container>
                {this.state.prikazano ? this.options.map((item, index) => <Option removeOption={this.removeOption} key={item.identifikator} updateoption={this.updateOption} props={item} parent={this}></Option>) : null}
                <Button
                    style={{ margin: 10 }}
                    variant="warning"
                    onClick={() => {
                        this.addOption()
                        this.setState({ prikazano: true })
                    }}>
                    Nova opcija</Button>
                <Button
                    style={{ margin: 10 }}

                    variant="light"
                    onClick={() => { this.setState({ prikazano: !this.state.prikazano }) }}
                >{this.state.prikazano ? "Sakrij opcije" : "Prikazi opcije"}</Button>
            </Container>
        )
    }
}
const styles = {
    container: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '5%'
    },
    naziv: {
        width: '30%',
        height: '10%',
        marginRight: 20, marginLeft: 5
    },
    minimaks: {
        width: '30%',
        margin: 10

    }
}