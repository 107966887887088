import { Component } from "react";
import React from "react";
import { Container } from "react-bootstrap"
import { Category } from "../MenuComponents/Category";
const brojacc = { vrednost: 1 };
export class Meni extends Component {
    constructor(props) {
        super(props);
        this.aktivniMeni = 0;
        this.meni = { naziv: "Ucitavanje", kategorije: [], nedostupno: {} };
        global.meni = this.meni;
        this.items = []
        this.state = { brojac: 0 }

    }
    generisiIdentifikator() {
        let broj = brojacc.vrednost++;
        return broj;
    }
    componentDidMount() {
        let self = this;
        global.socket.emit("zatraziListuMenijaRestoran");
        global.socket.once("odgovorNaTrazenjeListeMenijaRestoran", function (podaci) {
            if (podaci.uspeh) {
                self.menijiAdmin = podaci.menijiAdmin;
                self.menijiKorisnik = podaci.menijiKorisnik;
                self.meniUUpotrebi = podaci.meniUUpotrebi;
                global.socket.emit("zatraziMeniRestoran", { _id: podaci.meniUUpotrebi });
                global.socket.once("odgovorNaTrazenjeMenijaRestoran", function (podaci) {
                    self.importMenu(podaci.meni);
                    self.setState({ brojac: self.state.brojac + 1 });
                })
            }
            else {

            }
        });
    }
    updateCategory = (item, id) => {
        let self = this;
        let pom = self.meni.kategorije.slice()
        pom[id] = item
        self.meni.kategorije = pom;
        self.setState({ brojac: self.state.brojac + 1 });

    }
    removeCategory = (id) => {
        let self = this;
        if (self.meni.nedostupno[self.meni.kategorije[id].identifikator])
            delete self.meni.nedostupno[self.meni.kategorije[id].identifikator];
        for (let s in self.meni.kategorije[id].stavke) {
            let S = self.meni.kategorije[id].stavke[s];
            if (self.meni.nedostupno[S.identifikator])
                delete self.meni.nedostupno[S.identifikator];
            for (let go in S.grupeopcija) {
                let GO = S.grupeopcija[go];
                if (self.meni.nedostupno[GO.identifikator])
                    delete self.meni.nedostupno[GO.identifikator];
                for (let o in GO.opcije) {
                    let O = GO.opcije[o];
                    if (self.meni.nedostupno[O.identifikator])
                        delete self.meni.nedostupno[O.identifikator];
                }
            }
        }
        self.meni.kategorije.splice(id, 1)
        self.setState({ brojac: self.state.brojac + 1 });
    }
    addCategory = (id) => {
        let self = this;
        let pom = self.meni.kategorije.slice()
        pom.push({ naziv: "Nova kategorija", slika: "", stavke: [], identifikator: this.generisiIdentifikator() })
        self.meni.kategorije = pom;
        self.setState({ brojac: self.state.brojac + 1 });
    }
    importMenu = (meni) => {
        let self = this;
        for (let k in meni.kategorije) {
            if (meni.kategorije[k].identifikator >= brojacc.vrednost) {
                brojacc.vrednost = meni.kategorije[k].identifikator + 1;
            }
            for (let s in meni.kategorije[k].stavke) {
                if (meni.kategorije[k].stavke[s].identifikator >= brojacc.vrednost) {
                    brojacc.vrednost = meni.kategorije[k].stavke[s].identifikator + 1;
                }
                for (let go in meni.kategorije[k].stavke[s].grupeopcija) {
                    if (meni.kategorije[k].stavke[s].grupeopcija[go].identifikator >= brojacc.vrednost) {
                        brojacc.vrednost = meni.kategorije[k].stavke[s].grupeopcija[go].identifikator + 1;
                    }
                    for (let o in meni.kategorije[k].stavke[s].grupeopcija[go].opcije) {
                        if (meni.kategorije[k].stavke[s].grupeopcija[go].opcije[o].identifikator >= brojacc.vrednost) {
                            brojacc.vrednost = meni.kategorije[k].stavke[s].grupeopcija[go].opcije[o].identifikator + 1;
                        }
                    }
                }
            }
        }
        self.meni = meni;
        global.meni = self.meni;
    }
    saveMenu = () => {
        let self = this;
        //Mozda treba da se poveze
        //self.meni.kategorije = self.meni.kategorije;
        //Uklanjanje nedozvoljenih stvari
        for (let k in self.meni.kategorije) {
            let K = self.meni.kategorije[k];
            for (let s in K.stavke) {
                let S = K.stavke[s];
                for (let go = 0; go < S.grupeopcija.length; go++) {
                    let GO = S.grupeopcija[go];
                    for (let o in GO.opcije) {
                        let O = GO.opcije[o];
                        //ZA OPCIJU

                    }
                    //ZA GRUPU OPCIJA
                    if (GO.opcije.length == 0) {
                        S.grupeopcija.splice(go, 1);
                        go--;
                        continue;
                    }
                    GO.min = parseInt(GO.min);
                    GO.max = parseInt(GO.max);
                    if (!GO.min) {
                        GO.min = 0;
                    }
                    if (!GO.max) {
                        GO.max = GO.min ? GO.min : 1;
                    }
                    if (GO.max < GO.min) {
                        GO.max = GO.min;
                    }
                    if (GO.max > GO.opcije.length) {
                        GO.max = GO.opcije.length;
                    }
                    if (GO.max < GO.min) {
                        delete S.grupeopcija[go]; //Brise se ako bude jos nesto retardirano
                    }
                }
                //ZA STAVKU

            }
            //ZA KATEGORIJU

        }
        global.socket.emit("sacuvajMeniRestoran", { meni: self.meni });
        global.socket.once("odgovorNaCuvanjeMenijaRestoran", function (rezultat) {
            if (rezultat.uspeh) {
                alert("USPESNO");
                //window.location.reload(false);
            }
            else {
                alert("NEUSPESNO");
            }
        })

    }

    render() {
        let self = this;
        console.log(self.meni);
        return (<Container style={styles.mainContainer}>
            <a style={styles.naslov}> {self.meni.naziv}</a>
            {self.meni.kategorije.map((item, index) => <Category meni={self.meni} props={item} id={index} key={item.identifikator} parent={self} updatecategory={self.updateCategory} removeCategory={self.removeCategory} generisiIdentifikator={self.generisiIdentifikator} />)}

            <Container style={styles.addCategory} onClick={self.addCategory}>
                <a style={{ fontFamily: 'sans-serif', fontSize: 18, color: "white" }}>Nova kategorija</a>
            </Container>
            <Container style={styles.saveButton} onClick={self.saveMenu}>
                <a style={{ fontFamily: 'sans-serif', fontSize: 18, color: "white" }}>Sacuvaj meni</a>
            </Container>
        </Container>);
    }
}
const styles = {
    naslov: { fontSize: 30, fontFamily: "sans-serif" },
    mainContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
    addCategory: { width: '50%', height: 50, backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10, marginBottom: '3vh' },
    saveButton: { width: '50%', height: 50, backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10, marginBottom: '10vh' }
}