import React, { Component, createRef } from 'react'
import { Navbar, Nav, Container, NavDropdown, Button } from 'react-bootstrap'
import { Link } from 'react-scroll'
import Footer from '../../Components/Footer.js'
import '../../Styles/Landing.css'

export class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.scollToRef = createRef();
    this.state = { more1: false }

  }
  render() {
    return (
      <div>
        <div style={styles.container}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{ position: "sticky", top: 0 }}>
            <Container style={{ flex: 1 }}>
              <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: "baseline" }}>
                <img src="https://img.onl/z27Hf2" style={{ width: '8vh', height: '7vh', borderRadius: 5, marginRight: "1vw" }}></img>
                <Navbar.Brand href="#home" style={{ marginTop: 15, alignSelf: "center" }} >Guest</Navbar.Brand>
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link ><Link activeClass="active" to="about">O nama</Link></Nav.Link>
                  <Nav.Link href="/restoran/pricing">Cene</Nav.Link>
                  <NavDropdown title="Benefiti" id="collasible-nav-dropdown">
                    <NavDropdown.Item ><Link activeClass="active" to="speed">Brža usluga</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link activeClass="active" to="statistics">Statistika</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link activeClass="active" to="visibility">Više vidljivosti</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item ><Link activeClass="active" to="loyalty">Loyalty program</Link></NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="https://play.google.com/store/apps/details?id=com.fastguest.guest">Preuzmite korisničku aplikaciju</Nav.Link>

                </Nav>
                <Nav>
                  <Nav.Link href="/restoran/registracija">Registrujte svoj restoran</Nav.Link>
                  <Nav.Link href="/restoran/prijava">Prijavite se</Nav.Link>

                  <Nav.Link eventKey={2} href="/restoran/contact">
                    Kontaktirajte nas!
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <p class="text-center" id="about" style={{ marginTop: 20, fontSize: 40, color: "gray" }}>Šta je Guest?</p>
        <Container style={styles.txtContainer}>
          <a style={{ alignSelf: 'center' }}>
            Guest je restoranska platforma koja povećava brzinu usluge, efikasnost zaposlenih i pruža svu neophodnu analitiku za poboljšanje oglašavanja i iskustva kupaca. Takođe vam pružamo priliku da distribuirate sopstvene bodove lojalnosti tako što vam omogućavamo da kreirate poseban meni gde su prikazane cene u navedenim poenima.          </a>
        </Container>
        <p class="text-center" style={{ marginTop: 20, fontSize: 40, color: "gray" }}>Kako to funkcioniše?</p>
        <Container ref={this.scollToRef} id="kontis" style={styles.txtContainer}>
          <a style={{ alignSelf: 'center' }}>
            Platforma se sastoji od aplikacije za kupce i veb aplikacije za preduzeća. U aplikaciji korisnici mogu unapred da naruče u registrovanim restoranima, bez potrebe da čekaju red samo da bi obavestili zaposlene šta žele da naruče. Ukoliko korisnici nemaju aplikaciju, moguće je da skeniraju QR kod postavljen u restoranu, kako bi mogli da isprobaju iskustvo brzog naručivanja. Kada njihova porudžbina bude spremna, dobiće obaveštenje.
            Ovo vašem preduzeću daje više vremena za pripremu porudžbina i povećava zadržavanje klijenata pomoću poena lojalnosti koji se lako koriste.{this.state.more1 ? "Vi kao restoran dobijate pristup sopstvenoj konzoli za prijem i prihvatanje porudžbina kada se registrujete. Ovu konzolu možete koristiti na bilo kom računaru, tabletu ili mobilnom uređaju. Kada primite porudžbinu možete videti predviđeno vreme dolaska i sadržaj te porudžbine, takođe možete i odštampati tu porudžbinu ako je vaš uređaj povezan sa fiskalnim štampačem. Na svom poslovnom profilu možete pratiti i statistiku svih porudžbina (vreme, cena, artikli, zauzetost, nelični podaci o klijentima. ..) koji vam takođe pomaže da prilagodite meni ili pripremite porudžbine na osnovu tih podataka. Ovo skida opterećenje sa vaših zaposlenih i čini ih efikasnijim. Možete da podesite koje stavke želite da prikazujete u meniju lojalnosti u kojem birate cene u bodovi lojalnosti (za svaku porudžbinu u vašem restoranu kupac dobija 1/10 cene u poenima lojalnosti). Ovi poeni su posebni za svaki restoran i korisnik ih može potrošiti samo u vašem restoranu. " :
              null}
          </a>
          <br />
          <Container
            style={{ textAlign: 'center', marginTop: '1vh' }}>
            <a
              onClick={() => {
                this.setState({ more1: !this.state.more1 })
              }} class="text-center" style={{ marginTop: 20, fontSize: 15, color: "gray" }}>{this.state.more1 == false ? "Više..." : "Manje"}</a>
          </Container>
        </Container>
        <p class="text-center" style={{ marginTop: 20, fontSize: 40, color: "gray" }}>Kako će to pomoći mom poslu?</p>
        <Container style={styles.txtContainer}>
          <h2 id="speed" style={{ alignSelf: "center", fontSize: 25, color: "#252525" }}> Brzina i efikasnost usluge</h2>
          <a style={{ alignSelf: 'center' }}>
            Kupci mogu naručiti pre nego što stignu, dajući tako vašem uslužnom osoblju i kuvaru vremena da unapred pripreme obroke. Veliki redovi više neće obeshrabriti ljude da naruče kada ne moraju da čekaju u redu da bi naručili.          </a>
        </Container>
        <Container style={styles.txtContainer}>
          <h2 id="statistics" style={{ alignSelf: "center", fontSize: 25, color: "#252525" }}>Statistika</h2>
          <a style={{ alignSelf: 'center' }}>
            Možete da proverite podatke o porudžbinama u bilo kom trenutku na bilo kom uređaju samo klikom na karticu statistike na nalogu vašeg restorana. Prikazuje vam podatke koje možete da iskoristite kako biste dobili uvid u to šta vaše mušterije žele, što bi dalje moglo da vam poboljša uslugu i efikasnost.          </a>
        </Container>
        <Container style={styles.txtContainer}>
          <h2 id="visibility" style={{ alignSelf: "center", fontSize: 25, color: "#252525" }}>Povećana vidljivost</h2>
          <a style={{ alignSelf: 'center' }}>
            Kada vaš restoran bude registrovan i prihvaćen, bićete prikazani na naslovnoj strani i mapi svim korisnicima u radijusu od 40 km. Ovo pomaže ljudima da lakše pronađu vaš restoran, posebno ako su putnici koji prolaze. Kada bude kasno, vaš objekat će biti lakši vidljivo korisnicima u blizini koji traže brzu užinu.          </a>
        </Container>
        <Container style={styles.txtContainer}>
          <h2 id="loyalty" style={{ alignSelf: "center", fontSize: 25, color: "#252525" }}>Program lojalnosti</h2>
          <a style={{ alignSelf: 'center' }}>
            Guest program lojalnosti je prvi te vrste. Možete podesiti koje stavke će biti dostupne u meniju lojalnosti (kojima korisnici pristupaju klikom na dugme koje se prikazuju u redovnom meniju aplikacije) i da izaberete cene tih artikala u bodovima lojalnosti. Za svaku  porudžbinu korisnik dobija 1/10 cene u poenima lojalnosti. Nema više kartica ili 20 različitih aplikacija za kupone koje rade istu stvar. Korišćenjem ovoga možete zadržati više klijenata i poboljšati promet.          </a>
        </Container>
        <Footer />
      </div>

    )
  }
}

const styles = {
  container: {
    backgroundImage: `url(https://connect.regencycenters.com/hubfs/blog-files/burger-fries_shutterstock_169468583.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '98.75vw',
    height: '90vh',
    justifyContent: 'center', alignItems: 'center'
  },
  txtContainer: {
    width: '80vw',
    display: 'flex', flexDirection: 'column',
    marginTop: '2vh',
    padding: 20, borderRadius: 20, marginBottom: '10vh', justifyContent: 'center', alignItems: 'center'
  }

};