import { Component } from "react";
import { isMobile } from "react-device-detect";
import { Container, Button } from "react-bootstrap";
import Timekeeper from 'react-timekeeper';
import Modal from 'react-modal';
import { TimeView } from '../Components/TimeView'
import { Tags } from '../Components/Tags'
import Compressor from 'compressorjs';
import Switch from "react-switch";
import { Redirect } from 'react-router-dom';
import { Modal as ModalBootstrap } from "react-bootstrap";
import { QRView } from "../Components/QRView";

const IMAGEHOST = "https://guest.fra1.digitaloceanspaces.com";


export class Upravljanje extends Component {

    constructor(props) {
        super(props)
        /*this.radnavremena = {
            ponedeljak: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            utorak: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            sreda: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            cetvrtak: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            petak: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            subota: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            },
            nedelja: {
                radi: false,
                pocetak: "00:00",
                kraj: "00:00"
            }
        }*/
        this.oznake = [];
        this.state = {
            naziv: "",
            adresa: "",
            mejl: "",
            telefon: "",
            modal: false,
            start: true,
            day: "",
            logo: "",
            baner: "",
            zaOvde: true,
            zaPoneti: false,
            rezervacijaMesta: false,
            radi: true,
            otvorenQRModal: false,
            radnavremena: {}
        }
    }
    componentDidMount = () => {
        let self = this;
        global.socket.emit("procitajPodatkeORestoranu");
        global.socket.once("odgovorNaTrazenjePodatakaORestoranu", function (rezultat) {
            global.restoran = rezultat.restoran;
            self.setState({ radnavremena: rezultat.restoran.radnoVreme })
            self.oznake = rezultat.restoran.oznake;
            self.setState({ logo: rezultat.restoran.logo, baner: rezultat.restoran.baner, naziv: rezultat.restoran.naziv, adresa: rezultat.restoran.adresa, mejl: rezultat.restoran.mejl, telefon: rezultat.restoran.broj_telefona, zaOvde: rezultat.restoran.naciniPreuzimanja.zaOvde, zaPoneti: rezultat.restoran.naciniPreuzimanja.zaPoneti, rezervacijaMesta: rezultat.restoran.naciniPreuzimanja.rezervacijaMesta });
        })
    }

    setDayIn = (data) => {
        if (this.state.start)
            this.state.radnavremena[this.state.day].pocetak = data.formatted24
        else {
            this.state.radnavremena[this.state.day].kraj = data.formatted24
            /*if (this.radnavremena[this.state.day].pocetak == this.radnavremena[this.state.day].kraj && this.radnavremena[this.state.day].kraj != "00:00") {
                this.radnavremena[this.state.day].kraj = "22:00"
            }*/
        }

    }
    setTheDay = (data, mod) => {
        if (mod == "clear") {
            //this.radnavremena[data].radi = !this.radnavremena[data].radi
            this.setState({ day: data })
        }
        else {
            this.setState({ day: data, modal: true })
        }

    }
    getPickerValue = value => {
    };
    pickBack = () => {

    }

    pickLogo() {
    }
    render() {
        let self = this;
        return <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", textAlign: "center", flexDirection: "column" }}>
            <ModalBootstrap show={self.state.otvorenQRModal} onHide={() => {
                self.setState({ otvorenQRModal: false });
            }}>
                <ModalBootstrap.Header closeButton>
                    <ModalBootstrap.Title>QR kod</ModalBootstrap.Title>
                </ModalBootstrap.Header>
                {
                    <ModalBootstrap.Body style={{ background: "#f1f747" }} >
                        <QRView _idRestorana={global.restoran._id}></QRView>
                    </ModalBootstrap.Body>
                }

                <ModalBootstrap.Footer>
                    <Button variant="primary" onClick={
                        () => {
                            self.setState({ otvorenQRModal: false });
                        }
                    }>
                        Zatvori
                    </Button>
                </ModalBootstrap.Footer>
            </ModalBootstrap>
            <a style={{ fontSize: isMobile ? 23 : 40, color: "black", marginTop: 10 }}>Upravljanje</a>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: "column", alignItems: 'center' }}>
                <input class="form-control" placeholder="Naziv restorana" style={styles.input} value={self.state.naziv} onChange={(event) => { self.setState({ naziv: event.target.value }); }}></input>
                <input class="form-control" placeholder="Adresa restorana" style={styles.input} value={self.state.adresa} onChange={(event) => { self.setState({ adresa: event.target.value }); }}></input>
                <input class="form-control" placeholder="Broj telefona restorana" style={styles.input} value={self.state.telefon} onChange={(event) => { self.setState({ telefon: event.target.value }); }}></input>
                <input class="form-control" placeholder="Mejl restorana" style={styles.input} value={self.state.mejl} onChange={(event) => { self.setState({ mejl: event.target.value }); }}></input>


            </div>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>
            <Button variant="warning" style={{ width: isMobile ? '90vw' : '40vw', height: isMobile ? '7vh' : '8vh', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 20 }}
                onClick={() => {
                    this.setState({ otvorenQRModal: true })
                }} >Prikaži QR kod </Button>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>
            <Button variant="warning" style={{ width: isMobile ? '90vw' : '40vw', height: isMobile ? '7vh' : '8vh', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 20 }}
                onClick={() => { this.setState({ radi: false }) }} >Promenite lozinku {this.state.radi ? null : <Redirect to='/lozinka'></Redirect>}
            </Button>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>
            <a style={{ fontSize: isMobile ? 20 : 35, color: "gray", marginTop: 10 }}>Logotipi</a>

            <Container style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center', width: "100vw",
                flexDirection: isMobile ? 'column' : 'row',
                borderWidth: 2, borderBottomColor: "black", alignItems: 'center', alignSelf: 'center', backgroundColor: '#f5f5f5', borderRadius: 5, padding: 5
            }}>
                <img
                    style={styles.backImg}
                    src={self.state.baner ? (IMAGEHOST + self.state.baner) : IMAGEHOST + "/slike/guestreact/dodajsliku.png"}
                ></img>
                <img
                    onClick={this.pickLogo}
                    style={styles.logo}
                    src={self.state.logo ? (IMAGEHOST + self.state.logo) : IMAGEHOST + "/slike/guestreact/dodajsliku.png"}></img>
            </Container>
            <Container style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center', width: "100vw",
                flexDirection: isMobile ? 'column' : 'row',
                borderWidth: 2, borderBottomColor: "black", alignItems: 'center', alignSelf: 'center', backgroundColor: '#f5f5f5', borderRadius: 5, padding: 5
            }}>
                <input
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                        const image = event.target.files[0];
                        new Compressor(image, {
                            maxWidth: 1440,
                            maxHeight: 480,
                            quality: 1,
                            success: (compressedResult) => {
                                global.socket.emit("uploadujSliku", { slika: compressedResult, lokacija: "baneri" });
                                global.socket.once("odgovorNaUploadSlike", function (rez) {
                                    if (rez.uspeh) {
                                        self.setState({ baner: rez.url });
                                    }
                                    else {
                                        alert("Molimo pokusajte ponovo");
                                    }
                                })
                            },
                        });
                    }}
                />
                <input
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                        const image = event.target.files[0];
                        new Compressor(image, {
                            maxWidth: 600,
                            maxHeight: 600,
                            quality: 0.8,
                            success: (compressedResult) => {
                                global.socket.emit("uploadujSliku", { slika: compressedResult, lokacija: "logoi" });
                                global.socket.once("odgovorNaUploadSlike", function (rez) {
                                    if (rez.uspeh) {
                                        self.setState({ logo: rez.url });
                                    }
                                    else {
                                        alert("Molimo pokusajte ponovo");
                                    }
                                })
                            },
                        });
                    }}
                />
            </Container>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>
            <a style={{ fontSize: isMobile ? 20 : 35, color: "gray", marginTop: 10, marginBottom: 10 }}>Radno vreme</a>
            <Modal
                isOpen={this.state.modal}
                contentLabel="Example Modal"
                className={`shadow p`}

                style={{
                    overlay: {
                        display: 'flex',
                        alignSelf: 'center'
                        , alignItems: 'center', justifyContent: "center", flexDirection: 'column'
                    },
                    content: {
                        display: 'flex', backgroundColor: 'whitesmoke',
                        flexDirection: 'column', justifyContent: 'center', textAlign: 'center', borderRadius: 10, padding: 3, bordercolor: 'black'
                    }
                }}
            >
                <a style={{ fontFamily: "sans-serif", fontSize: 18 }}>{this.state.start ? "Izaberite vreme otvaranja" : "Izaberite vreme zatvaranja"}</a>
                <Timekeeper
                    time={this.state.start ? "9:00" : "22:00"}
                    className=""
                    onDoneClick={(data) => {
                        this.setDayIn(data)
                        if (this.state.start) {
                            this.setState({ start: false })
                        }
                        else {
                            this.setState({ modal: false, start: true })
                        }
                    }}
                />

            </Modal>
            <Container style={{ marginBottom: isMobile ? 0 : 40, width: '60%', flexDirection: isMobile ? 'column' : 'row', display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                <TimeView value={this.state.radnavremena.ponedeljak || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="ponedeljak"></TimeView>
                <TimeView value={this.state.radnavremena.utorak || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="utorak"></TimeView>
                <TimeView value={this.state.radnavremena.sreda || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="sreda"></TimeView>
                <TimeView value={this.state.radnavremena.cetvrtak || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="cetvrtak"></TimeView>


            </Container>
            <Container style={{ marginBottom: 40, width: '60%', flexDirection: isMobile ? 'column' : 'row', display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'baseline' }}>

                <TimeView value={this.state.radnavremena.petak || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="petak"></TimeView>
                <TimeView value={this.state.radnavremena.subota || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="subota"></TimeView>
                <TimeView value={this.state.radnavremena.nedelja || { radi: true, pocetak: "00:01", kraj: "00:02" }} parentCallback={this.setTheDay} title="nedelja"></TimeView>
            </Container>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>
            <a style={{ fontSize: isMobile ? 20 : 35, color: "gray", marginTop: 10, marginBottom: 10 }}>Nacini preuzimanja </a>

            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexDirection: 'row', alignSelf: 'center', width: isMobile ? '90vw' : '50vw' }}>
                <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a style={{ marginRight: 10, fontFamily: 'sans-serif' }}>Narudzbine za poneti  </a>
                    <Switch onChange={(event) => { this.setState({ zaPoneti: !this.state.zaPoneti }) }}
                        checked={this.state.zaPoneti}
                    ></Switch>
                </Container>
                <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a style={{ marginRight: 10, fontFamily: 'sans-serif' }}>Narudzbine u restoranu  </a>
                    <Switch onChange={(event) => { this.setState({ zaOvde: !this.state.zaOvde }) }}
                        checked={this.state.zaOvde}
                    ></Switch>
                </Container>
                <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a style={{ marginRight: 10, fontFamily: 'sans-serif' }}>Rezervacija mesta  </a>
                    <Switch onChange={(event) => { this.setState({ rezervacijaMesta: !this.state.rezervacijaMesta }) }}
                        checked={this.state.rezervacijaMesta}
                    ></Switch>
                </Container>
            </Container>
            <div style={{ width: "90%", height: 1, backgroundColor: 'gray', margin: 20 }}></div>

            <Container style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10vh' }}>
                <a style={{ fontSize: isMobile ? 20 : 35, color: "gray", marginTop: 10, marginBottom: 10 }}>Kategorije hrane </a>
                <Tags parent={self}></Tags>
            </Container>

            <Button
                onClick={() => {
                    let obj = {
                        naziv: self.state.naziv,
                        adresa: self.state.adresa,
                        broj_telefona: self.state.telefon,
                        mejl: self.state.mejl.replace(/ /g, "").toLowerCase(),
                        oznake: self.oznake,
                        naciniPreuzimanja: {
                            zaOvde: self.state.zaOvde,
                            zaPoneti: self.state.zaPoneti,
                            rezervacijaMesta: self.state.rezervacijaMesta
                        },
                        radnoVreme: self.state.radnavremena,
                        logo: self.state.logo,
                        baner: self.state.baner,
                    };
                    global.socket.emit("uploadujPodatkeORestoranu", obj);
                    global.socket.once("odgovorNaUpdatovanjePodatakaORestoranu", function (rez) {
                        if (rez.uspeh) {
                            window.location.reload(false);
                        }
                        else {
                            alert("Greska", "greska");
                        }
                    })
                }}
                variant="outline-success"
                style={{ width: isMobile ? '90vw' : '40vw', height: isMobile ? '8vh' : '10vh', marginBottom: '5vh', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 20 }}
            >Sačuvajte promene</Button>
        </div >;
    }
}
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column', width: '100%', marginBottom: '10vh', alignItems: 'center', justifyContent: 'center', alignSelf: 'center'
    },
    backImg: {
        width: isMobile ? '95vw' : '60vw',
        height: isMobile ? '30vh' : '60vh',
        borderRadius: 10, margin: 10

    },
    logo: {

        width: isMobile ? '40vw' : '35vh',
        height: isMobile ? '40vw' : '35vh',
        borderRadius: 10
    },
    text1: {
        fontSize: 25, margin: 10
    },
    input: {
        width: isMobile ? '90vw' : '40vw',
        height: isMobile ? '6vh' : '8vh',
        marginTop: 10, borderRadius: 10
    },
    day: {
        backgroundColor: "black", borderRadius: 5,
        width: isMobile ? '90vw' : '40vw',
        height: isMobile ? '10vh' : '5vh', marginTop: 10,
        justifyContent: 'space-between',
        display: 'flex',
    }
}
// <Timekeeper
	//	time={this.state.time}
      //  onDoneClick={() => {}}
	///>