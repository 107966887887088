import { Component } from "react";
import * as V from 'victory';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryP } from 'victory';
import { isMobile } from "react-device-detect";

const data = [
    { quarter: 1, earnings: 13000 },
    { quarter: 2, earnings: 16500 },
    { quarter: 3, earnings: 14250 },
    { quarter: 4, earnings: 19000 }
];
export class Statistika extends Component {
    constructor(props) {
        super(props);
        this.state = { brojac: 0, stavke: [] };
        this.narudzbine = [];
        this.meniji = [];
        this.stavke = [];
    }

    obradiDobijeneNarudzbine = function (narudzbine) {
        let self = this;
        for (let i = 0; i < narudzbine.length; i++) {
            self.obradiNarudzbinu(narudzbine[i]);
        }
    }
    obradiNarudzbinu = function (narudzbina) {
        let ukc = 0;
        for (let j = 0; j < narudzbina.korpa.length; j++) {
            ukc += parseInt(narudzbina.korpa[j].ukupnacena);
        }
        narudzbina.cena = ukc;
    }
    napuniStavke = function () {
        for (let i = 0; i < this.narudzbine.length; i++) {
            let korpa = this.narudzbine[i].korpa;
            for (let j = 0; j < korpa.length; j++) {
                let pom = 0;
                for (let k in this.stavke) {
                    if (this.stavke[k].naziv == korpa[j].naziv) {
                        this.stavke[k].broj++;
                        pom = 1;
                    }
                }
                if (pom == 0) { this.stavke.push({ naziv: korpa[j].naziv, broj: 1 }) }

            }
        }
        this.setState({ stavke: this.stavke });
    }
    componentDidMount() {
        let self = this;
        global.socket.emit("zatraziListuNarudzbina");
        global.socket.once("stiglaListaNarudzbina", function (podaci) {
            if (podaci.uspeh) {
                self.narudzbine = podaci.narudzbine;
                self.obradiDobijeneNarudzbine(self.narudzbine);
                self.setState({ brojac: self.state.brojac + 1 });
            }
        });

        global.socket.emit("zatraziListuMenijaRestoran");
        global.socket.once("odgovorNaTrazenjeListeMenijaRestoran", function (podaci) {
            if (podaci.uspeh) {
                self.menijiAdmin = podaci.menijiAdmin;
                self.menijiKorisnik = podaci.menijiKorisnik;
                self.meniUUpotrebi = podaci.meniUUpotrebi;
                self.setState({ brojac: self.state.brojac + 1 });

                for (let i in self.menijiAdmin) {
                    global.socket.emit("zatraziMeniRestoran", { _id: i });
                    global.socket.once("odgovorNaTrazenjeMenijaRestoran", function (podaci) {
                        //self.importMenu(podaci.meni);//Ako budes trebao nekako da obradis
                        self.menijiAdmin[i] = podaci.meni;
                        self.meniji.push(podaci.meni);
                        self.setState({ brojac: self.state.brojac + 1 });
                    })
                }
                for (let i in self.menijiKorisnik) {
                    global.socket.emit("zatraziMeniRestoran", { _id: i });
                    global.socket.once("odgovorNaTrazenjeMenijaRestoran", function (podaci) {
                        //self.importMenu(podaci.meni);//Ako budes trebao nekako da obradis
                        self.menijiKorisnik[i] = podaci.meni;
                        self.meniji.push(podaci.meni);
                        self.setState({ brojac: self.state.brojac + 1 });
                    })
                }


            }
            else {
            }
            self.napuniStavke()

        });
    }

    render() {
        let self = this;

        return <div style={styles.div}>
            <a style={styles.naslov}>Stavke po kolicini prodaje</a>
            <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={20}

            >
                <VictoryBar
                    style={{ data: { fill: "#e6f466" } }}

                    data={this.state.stavke.length > 0 ? this.state.stavke : data}
                    x="naziv"
                    y="broj"

                />
            </VictoryChart>
        </div>
    }
}
const styles = {
    div: {
        display: 'flex',
        width: '100vw', height: '100vh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    },
    naslov: {
        fontSize: isMobile ? '3vh' : '4vh', fontFamily: 'sans-serif', marginTop: '10px'
    }

}