import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Container, Button } from "react-bootstrap";
import { Item } from "./Item";
import Compressor from 'compressorjs';

const IMAGEHOST = "https://guest.fra1.digitaloceanspaces.com";
export class Category extends Component {
    constructor(props) {
        super(props);

        this.state = { prikazano: false, prikazanaKategorija: false }
        this.stavke = this.props.props.stavke;
        this.naziv = this.props.props.naziv;
        this.slika = this.props.props.slika;
        this.identifikator = this.props.props.identifikator;
        this.generisiIdentifikator = this.props.generisiIdentifikator;
        this.slikainp = React.createRef();
        this.handleChange = this.handleChange.bind(this);

    }
    updateCategory = () => {
        this.props.updatecategory({ naziv: this.naziv, slika: this.slika, stavke: this.stavke, identifikator: this.identifikator }, this.props.id);

    }
    addItem = () => {
        let pom = this.stavke.slice()
        let c = pom.length
        pom.push({ id: c - 1, naziv: "Nova stavka", opis: "", slika: "", loyalty: -1, cena: 0, grupeopcija: [], identifikator: this.generisiIdentifikator() })
        this.stavke = pom;
        this.setState({ prikazano: true })
        this.updateCategory()


    }
    handleChange(event) {

        this.naziv = event.target.value;
        this.updateCategory()


    };
    updateItem = (item) => {
        let pom = this.stavke.slice()
        pom[item.id] = item
        this.stavke = pom;
        //this.setState({ stavke: pom })
        this.updateCategory()

    }
    removeItem = (id) => {
        let pom = this.stavke.slice();
        if (global.meni.nedostupno[this.stavke[id].identifikator]) {
            delete global.meni.nedostupno[this.stavke[id].identifikator];
        }
        for (let go in this.stavke[id].grupeopcija) {
            let GO = this.stavke[id].grupeopcija[go];
            if (global.meni.nedostupno[GO.identifikator])
                delete global.meni.nedostupno[GO.identifikator];
            for (let o in GO.opcije) {
                let O = GO.opcije[o];
                if (global.meni.nedostupno[O.identifikator])
                    delete global.meni.nedostupno[O.identifikator];
            }
        }
        pom.splice(id, 1)
        this.stavke = pom;
        //this.setState({ stavke: pom })
        this.updateCategory()

    }

    render() {
        let self = this;
        return (

            this.state.prikazanaKategorija ? (<Container style={styles.mainContainer}>
                <input class="form-control" onChange={this.handleChange} style={styles.input} placeholder={this.props.naziv ? this.props.naziv : "Naziv kategorije"} value={this.naziv} ></input>
                <Container style={styles.container}>
                    <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img
                            src={this.slika ? IMAGEHOST + this.slika : IMAGEHOST + "/slike/guestreact/dodajsliku.png"}
                            style={styles.img}
                            onClick={() => {
                                this.slikainp.current.click();
                            }}
                        ></img>
                        <input
                            style={{ display: "none" }}
                            accept="image/*"
                            type="file"
                            ref={this.slikainp}
                            onChange={(event) => {
                                const image = event.target.files[0];
                                new Compressor(image, {
                                    maxWidth: 1200,
                                    maxHeight: 400,
                                    quality: 0.9,
                                    success: (compressedResult) => {
                                        global.socket.emit("uploadujSliku", { slika: compressedResult, lokacija: "kategorije" });
                                        global.socket.once("odgovorNaUploadSlike", function (rez) {
                                            if (rez.uspeh) {
                                                self.slika = rez.url;
                                                self.updateCategory();
                                            }
                                            else {
                                                alert("Molimo pokusajte ponovo");
                                            }
                                        })
                                    },
                                });
                            }}
                        />
                        <img
                            onClick={() => { this.props.removeCategory(this.props.id) }}
                            src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2F0%2F7%2Fe%2Fa%2F12074327311562940906milker_X_icon.svg.hi.png&f=1&nofb=1"
                            style={{ width: '3vw', height: '3vw', alignSelf: "flex-start", marginTop: 5 }}
                        />
                    </Container>
                    {this.state.prikazano ? this.stavke.map((item, index) => <Item removeItem={this.removeItem} id={index} key={item.identifikator} updateitem={this.updateItem} props={this.stavke[index]} generisiIdentifikator={self.generisiIdentifikator}>{item.naziv}</Item>) : null}
                    <Container style={styles.newItemButton} onClick={this.addItem}>New item</Container>
                    <Button variant="dark" style={{ width: '80%', marginBottom: 10 }}
                        onClick={() => { this.setState({ prikazano: !this.state.prikazano }) }}
                    >{this.state.prikazano ? "Sakrij stavke" : "Prikazi stavke"}</Button>
                    <Button
                        variant="outline-warning"
                        style={{ marginTop: 10, marginBottom: 10, width: '50%', alignSelf: 'center', fontFamily: 'sans-serif', borderRadius: 10 }}
                        onClick={() => {
                            this.setState({ prikazanaKategorija: !this.state.prikazanaKategorija })

                        }}
                    >Sakrij kategoriju</Button>
                </Container>
            </Container>) : <Button
                variant="warning"
                style={{ marginTop: 10, marginBottom: 10, width: '50%', alignSelf: 'center', fontFamily: 'sans-serif', borderRadius: 7, height: 50 }}
                onClick={() => {
                    this.setState({ prikazanaKategorija: !this.state.prikazanaKategorija })

                }}
            >Prikazi kategoriju {this.naziv}</Button>
        )
    }
}
const styles = {
    container: {
        width: isMobile ? '100vw' : '65vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e0e0e0',
        margin: 10,
        flexDirection: 'column', borderRadius: 10
    },
    mainContainer: {
        width: isMobile ? '100vw' : '40vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        flexDirection: 'column'
    },
    newItemButton: {
        width: '80%',
        height: '7vh', margin: 10,
        backgroundColor: 'white', borderRadius: 10, textAlign: 'center', textAlignVertical: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center',
    },
    img: { width: '90%', height: '70vh', margin: 7, borderRadius: 10 }
    , input: {}
}