import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';


export default class LightShow extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        self.color = props.color;
        this.props = props;

    }
    invertColor(bg) {
        bg = parseInt(Number(bg.replace('#', '0x')), 10)
        bg = ~bg
        bg = bg >>> 0
        bg = bg & 0x00ffffff
        bg = '#' + bg.toString(16).padStart(6, "0")

        return bg
    }
    render() {
        let self = this;
        self.color = this.props.color;
        return (<div onClick={self.props.onClick} style={{ position: 'fixed', background: self.color, color: self.invertColor(self.color), zIndex: 100, width: "100%", height: "100%", fontSize: "20vmin", textAlign: "center", fontFamily: "sans-serif", paddingTop: "10vh" }}>
            {self.props.tekst}
        </div>
        );
    }
}