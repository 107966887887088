import { Component } from "react";
import { Navbar1 } from "../../Components/Navbar1";
import {Container} from 'react-bootstrap'

export class Contact extends Component{
    render() {
        return <div style={{display: 'flex', flex:1,justifyContent: 'center',alignItems: 'center',flexDirection: 'column'}}>
            <Navbar1> </Navbar1>
            <Container style={styles.kont}>
            <a>Write us at : guestappmail@gmail.com</a>
            <a>Call us at : +381637443242</a>
            <Container>
                <a>And follow us on </a>
                <img src="https://workingwithdog.com/wp-content/uploads/2016/05/new_instagram_logo.jpg" style={{width:20,height:20}}/>
            <a> instagram </a>
            <a href="https://Instagram.com/guest.app" style={{color:"black"}}>@guest.app</a>
            </Container>
           </Container>
        </div>;
    }
}
const styles={
    txt:{

    },
    kont:{
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',justifyContent: 'center',flexDirection: 'column',textAlign: 'center',marginTop:100
    }
}