import React, { Component } from "react";
import Switch from "react-switch";
import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";


export class TimeView extends React.Component {
    constructor(props) {
        super(props)
        this.state = { dis: false }
    }
    handleClick = (e) => {
        this.props.value.radi = !this.props.value.radi;
        this.setState({ dis: !this.state.dis })
        if (this.props.value.radi) {
            this.props.parentCallback(this.props.title);
        }
    };
    render() {
        return (
            <Container style={{
                display: 'flex', flexDirection: 'column',
                width: isMobile ? '60vw' : '13vw',
                height: isMobile ? '5vh' : '5vh', margin: isMobile ? 20 : 5, alignItems: 'center',
                marginBottom: 20,
                marginTop: 20
            }}
            >
                <Container {...this.props}

                    style={styles.container}
                >
                    <a style={styles.title}>{this.props.title}</a>
                    <Switch onChange={() => {
                        this.props.value.radi = !this.props.value.radi;
                        this.setState({ dis: !this.state.dis })
                        if (!this.props.value.radi && isMobile) {
                            this.props.parentCallback(this.props.title, "clear");
                        }
                        else
                            if (!isMobile && this.props.value.radi) {
                                this.props.parentCallback(this.props.title, "clear");
                            }
                    }}
                        uncheckedIcon={false} checkedIcon={false} checked={this.props.value.radi} />
                </Container>
                <div>{this.props.value.pocetak} - {this.props.value.kraj}</div>
                <img

                    onClick={() => {
                        if (this.props.value.radi && isMobile) {
                            this.props.parentCallback(this.props.title, "change");
                        }
                        else
                            if (!isMobile && this.props.value.radi) {
                                this.props.parentCallback(this.props.title, "change");
                            }
                    }}
                    src="https://i.ibb.co/31H7yS3/clock-1.png" style={{ width: 40, height: 40, marginTop: 3, backgroundColor: 'whitesmoke', padding: 5, borderRadius: 30}}></img>
            </Container>
        )
    }
}

const styles = {
    container: {
        alignSelf: 'center',

        justifyContent: isMobile ? 'space-between' : 'space-around',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center', borderColor: 'gray', backgroundColor: '#f5f5f5', borderRadius: isMobile ? 5 : 10, textAlignVertical: 'center',
    },
    container2: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
    },
    title: {
        color: "black", fontSize: isMobile ? 15 : 20, marginRight: isMobile ? 0 : 3
    },
    button: {
        backgroundColor: "white"
    }
}
