import { React, Component, Platform } from "react";
import { Container, Button, Alert } from 'react-bootstrap'
import { isMobile } from "react-device-detect";
import { MDBSmoothScroll } from "mdbreact";
import { TrenutnaPorudzbina } from "../NarudzbineComponents/TrenutnaPorudzbina";
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { Modal as BootstrapModal } from "react-bootstrap";
import { NovaNarudzbina } from '../NarudzbineComponents/NovaNarudzbina'
import { PrihvacenaNarudzbina } from '../NarudzbineComponents/PrihvacenaNarudzbina'
import { BigDecimal } from "bigdecimal";
import Switch from "react-switch";
import Select from 'react-dropdown-select'
import zvukic from "./notiff.wav";
import LightShow from "../Components/LightShow";
import { GotovaNarudzbina } from "../NarudzbineComponents/GotovaNarudzbina";

const glupis = { self: null }

export class Narudzbine extends Component {
    constructor(props) {
        super(props);
        this.notif = new Audio(zvukic);
        //audio.addEventListener('ended', () => this.setState({ play: false }));
        this.notif.loop = false;
        this.vremena = [1, 2, 5, 10, 15, 20, 30];
        this.kesirana = {
            _id: "",
            vreme: new Date(),
            tip: 0,
            string: "00:00"
        };
        this.state = {
            megasmor: null,
            bojals: "#aaaaaa",
            inicijalnimodal: true,
            radi: true,
            trenutnoPrikazana: {},
            poruka: false,
            brojac: 0,
            tekstPoruke: "",
            rushPostavke: { ukljucen: false, akcija: "nista", porukakorisnicima: "", porukakorisniku: "" },
            rushOtvoren: false,
            rushMode: false,
            rushPoruka: "",
            rushAkcija: "nista",
            porukakorisniku: "",
            tekstObavestenja: "Imate novu narudžbinu",
            istorijaOtvorena: false,
            blokiraniKorisnici: {},
            idprihvacene: null,
            drugovreme: null,
            isMobile: (window.innerHeight > window.innerWidth),
            rotiraj: false,
            dostupnostModal: false,
            dostupnostInputText: "",
            dostupnostSearchText: "",
            nedostupno: {}
        };
        this.narudzbine = [];
        this.trebazvuk = true;
        this.dnoporuka = null;
        this.baciozvon = false;
        this.trebadazvoni = false;
        glupis.self = this;
        this.meni = { naziv: "Ucitavanje", kategorije: [] };
        this.otvNarudzbinaPoruke = {
            poruke: {
                niz: [],
                korisnikseen: true,
                restoranseen: true
            }
        };
        this.rushAkcije = [{ value: 1, label: "nista" }, { value: 2, label: "odbijanje" }, { value: 3, label: "poruka" }];
    }
    scrollToBottom = () => {
        if (this.dnoporuka) {
            this.dnoporuka.scrollIntoView();
        }
    }
    templejtBrzePoruke(minuti) {
        if (minuti <= 1) {
            return "Narudžbina će veoma uskoro biti gotova. "
        }
        return `Narudžbina će biti spremna za oko ${minuti} minuta. Molimo Vas da potvrdite da li Vam odgovara. `
    }
    templejtPrihvacenePoruke(minuti) {
        if (minuti <= 1) {
            return "Narudžbina će veoma uskoro biti gotova. "
        }
        return `Narudžbina će biti spremna za oko ${minuti} minuta. `
    }
    templejtNaVreme(vreme) {
        return `Narudžbina će biti spremna na vreme (${vreme}). `
    }
    isNumeric(str) {
        if (typeof str !== 'string') {
            return false;
        }

        const num = Number(str);

        if (Number.isInteger(num) && num > 0) {
            return true;
        }

        return false;
    }
    pustizvuk = function () {
        this.notif.play();
    }
    pauzirajZvuk = function () {
        let self = this;
        self.trebazvuk = false;
        setTimeout(() => {
            self.trebazvuk = true;
        }, 1000)
    }
    filterDobijenihNarudzbina = function (narudzbina) {
        return narudzbina.status == "neprihvacena" || narudzbina.status == "priprema" || narudzbina.status == "spremna";
    }
    obradiDobijeneNarudzbine = function (narudzbine) {
        let self = this;
        //narudzbine = narudzbine.filter(self.filterDobijenihNarudzbina);
        for (let i in narudzbine) {
            self.obradiNarudzbinu(narudzbine[i]);
        }
    }
    obradiNarudzbinu = function (narudzbina) {
        if (narudzbina.status == "zavrsena" || narudzbina.status == "odbijena" || narudzbina.status == "otkazana" || narudzbina.status == "blokirana") return;
        let self = this;
        let ukc = new BigDecimal(0);
        let sveok = true;
        let nadjena = false;
        let losa = false;
        let istek = global.cookies.get(narudzbina._id);
        if (istek) {
            narudzbina.brojac = Math.floor((istek - new Date().getTime()) / 60000);
            setTimeout(() => {
                self.narudzbinaTajmer(narudzbina.brojac, narudzbina._id, true);
                self.setState({ brojac: self.state.brojac + 1 });
            }, (istek - new Date().getTime()) % 60000);
        }
        for (let j = 0; j < narudzbina.korpa.length; j++) {
            let stavkakorpe = narudzbina.korpa[j];
            let cenas = new BigDecimal(0);
            nadjena = false;
            losa = false;
            for (let k in self.meni.kategorije) {
                let K = self.meni.kategorije[k];
                for (let s in K.stavke) {
                    let stavka = K.stavke[s];
                    if (!nadjena) {
                        if (stavka.naziv == stavkakorpe.naziv) {
                            let nadjenagrupaopcija = false;
                            let losagrupaopcija = false;
                            if (stavkakorpe.cena && !stavkakorpe.loyalty) {
                                if (stavka.cena != stavkakorpe.cena) {
                                    losa = true;
                                    //console.log("Losa zato sto se ne poklapaju cene");
                                }
                                cenas = cenas.add(new BigDecimal(stavkakorpe.kolicina).multiply(new BigDecimal(stavkakorpe.cena)));
                            }
                            else if (stavkakorpe.loyalty && !stavkakorpe.cena) {
                                if (stavkakorpe.kolicina * stavka.loyalty != stavkakorpe.loyalty) {
                                    losa = true;
                                    //console.log("Losa zato sto se ne poklapaju loyalty cene");
                                }
                            }
                            else {
                                //BESPLATNA
                                //console.log("Losa zato sto kombinacija loyalty i cene nije dobra");
                                //losa = true;
                            }
                            for (let gok in stavkakorpe.grupeopcija) {
                                nadjenagrupaopcija = false;
                                losagrupaopcija = false;
                                let GOK = stavkakorpe.grupeopcija[gok];
                                let cenago = new BigDecimal(0);
                                //console.log("Trazim grupu opcija: ");
                                //console.log(GOK);
                                for (let go in stavka.grupeopcija) {
                                    if (!nadjenagrupaopcija) {
                                        let GO = stavka.grupeopcija[go];
                                        if (GO.naziv == GOK.naziv) {
                                            //console.log("Nadjena grupa opcija sa istim nazivom: ");
                                            //console.log(GO);
                                            losagrupaopcija = false;
                                            let racopcije = 0;
                                            for (let ok in GOK.opcije) {
                                                let OK = GOK.opcije[ok];
                                                let nadjenaopcija = false;
                                                let cenao = new BigDecimal(0);
                                                for (let o in GO.opcije) {
                                                    if (!nadjenaopcija) {
                                                        let O = GO.opcije[o];
                                                        cenao = 0;
                                                        if (O.naziv == OK.naziv && O.cena == OK.cena && parseInt(OK.kolicina) <= parseInt(O.maxkolicina)) {
                                                            cenao = new BigDecimal(OK.cena).multiply(new BigDecimal(OK.kolicina));
                                                            if (O.racunajSamoJednom === false) {
                                                                //console.log(O.naziv + "Racunam vise puta")
                                                                racopcije += OK.kolicina;
                                                            }
                                                            else {
                                                                //console.log(O.naziv + "Racunam jednom")
                                                                racopcije++;
                                                            }
                                                            //console.log("Opcija nadjena");
                                                            nadjenaopcija = true;
                                                        }
                                                        else {
                                                            //console.log("Osobine opcije se ne poklapaju");

                                                            nadjenaopcija = false;
                                                        }
                                                    }
                                                }
                                                if (!nadjenaopcija) {
                                                    //console.log("Opcija nije nadjena u grupi");
                                                    losagrupaopcija = true;
                                                }
                                                else {
                                                    cenago = cenago.add(cenao);
                                                }
                                            }
                                            if (racopcije < parseInt(GO.min) || racopcije > parseInt(GO.max)) {
                                                //console.log("LGO1");
                                                //losagrupaopcija = true;
                                            }
                                            if (!losagrupaopcija) {
                                                nadjenagrupaopcija = true;

                                            }
                                            else {
                                                //console.log("Grupa opcija je losa i zato nije nadjena");
                                                nadjenagrupaopcija = false;
                                            }
                                        }
                                    }
                                }
                                if (!nadjenagrupaopcija) {
                                    //console.log("Losa zato sto nije nadjena grupa opcija");
                                    losa = true;
                                }
                                else {
                                    //console.log("Nadjena grupa opcija, pa dodajem na cenu stavke");
                                    cenas = cenas.add(cenago.multiply(new BigDecimal(stavkakorpe.kolicina)));
                                }
                            }
                            if (!losa) {
                                nadjena = true;
                            }
                        }
                    }
                }
            }
            //console.log("Ukupna: " + (new BigDecimal(narudzbina.korpa[j].ukupnacena)).toString() + "   Izracunata " + cenas.toString());
            //console.log(cenas);
            //console.log(new BigDecimal(narudzbina.korpa[j].ukupnacena));
            //let vazne = cenas.multiply()
            //oprasta desetinu evra
            if (!(cenas.multiply(new BigDecimal(100000)).compareTo(new BigDecimal(Math.floor(narudzbina.korpa[j].ukupnacena * 100000))) == 0)) {
                losa = true;
                //console.log("Ukupna cena stavke se ne poklapa sa izracunatom");
                //console.log("Ukupna: " + (new BigDecimal(narudzbina.korpa[j].ukupnacena)).toString() + "   Izracunata " + cenas.toString());
            }
            //console.log(nadjena);
            //console.log(losa);
            if (!nadjena || losa) sveok = false;
            else {
                ukc = ukc.add(cenas);
            }
        }
        //console.log("______________________________");
        //console.log(narudzbina);

        //Ako je losa cena dostave stavi sveok na false
        if (narudzbina.brojMesta == -1) {
            if (ukc < 1500) {
                if (narudzbina.cenadostave !== 0) {
                    //console.log("SVEOK FALSE")
                    sveok = false;
                }
            }
            else {
                if (narudzbina.cenadostave !== 0) {
                    //console.log("SVEOK FALSE")
                    sveok = false;
                }
            }
        }

        if (sveok) {
            //console.log("sve je ok");
        }
        else {
            if (narudzbina.status == "neprihvacena") {
                self.pauzirajZvuk();
                global.socket.emit("izmeniPorudzbinu", { _id: narudzbina._id, status: "odbijena" });
            }
            else if (narudzbina.status == "priprema" || narudzbina.status == "spremna") {
                self.pauzirajZvuk();
                global.socket.emit("izmeniPorudzbinu", { _id: narudzbina._id, status: "otkazana" });
            }
        }
        //console.log("______________________________");
        narudzbina.cena = parseInt(ukc.multiply(new BigDecimal(100)).toString()) / 100;

        if (narudzbina.kupon) {
            narudzbina.cena -= narudzbina.kupon.vrednost;
            if (narudzbina.cena < 0) {
                narudzbina.cena = 0;
            }
        }
    }
    handlujBlok(blokiraniKorisnici, narudzbina) {
        if (!blokiraniKorisnici[narudzbina.korisnik]) return false;
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: narudzbina._id, status: "blokirana" });
        return true;
    }
    handlujRushMode(rush, narudzbina) {
        if (!rush.ukljucen || rush.akcija == "nista") return;
        this.pauzirajZvuk();
        if (rush.akcija == "odbijanje")
            return global.socket.emit("izmeniPorudzbinu", { _id: narudzbina._id, status: "odbijena" });
        if (rush.akcija == "poruka")
            return global.socket.emit("posaljiPoruku", { tekst: rush.porukakorisniku, _id: narudzbina._id });
    }
    stampaj = (narudzbina) => {
        let stringvreme;
        let otvorena = narudzbina;
        if (otvorena.vremesakupljanja == null) {
            try {
                var h = new Date(otvorena.vreme_narudzbine + otvorena.putovanje.sekunde * 1000).getHours();
                var m = new Date(otvorena.vreme_narudzbine + otvorena.putovanje.sekunde * 1000).getMinutes();
                h = (h < 10) ? '0' + h : h;
                m = (m < 10) ? '0' + m : m;
                var output = h + ':' + m;
                stringvreme = "Što pre (~" + output + ")";
            }
            catch {
                stringvreme = "Što pre ";
            }
        }
        else {
            stringvreme = "Vreme dolaska: " + otvorena.vremesakupljanja;
        }
        let mywindow = window.open('', 'PRINT', 'height=400,width=200');
        let tabela = document.createElement("table");
        let thd = document.createElement("thead");
        let thtr = document.createElement("tr");
        let th1 = document.createElement("th");
        th1.className = "quantity";
        th1.innerText = "Q.";
        thtr.appendChild(th1);
        let th2 = document.createElement("th");
        th2.className = "description";
        th2.innerText = "Naziv";
        thtr.appendChild(th2);
        let th3 = document.createElement("th");
        th3.className = "price";
        th3.innerText = "Cena";
        thtr.appendChild(th3);
        thd.appendChild(thtr);
        tabela.appendChild(thd);
        let ukupnacenasvega = 0;
        let tbd = document.createElement("tbody");
        for (let i in otvorena.korpa) {
            ukupnacenasvega += otvorena.korpa[i].ukupnacena;
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = otvorena.korpa[i].kolicina;
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            let nazivstring = otvorena.korpa[i].naziv;
            for (let j in otvorena.korpa[i].grupeopcija) {
                let GO = otvorena.korpa[i].grupeopcija[j];
                nazivstring += "\n" + GO.naziv + ": ";
                for (let o in GO.opcije) {
                    let O = GO.opcije[o];
                    nazivstring += "\n• " + (O.kolicina != 1 ? (O.kolicina + "x ") : "") + O.naziv;
                }
                if (GO.opcije.length == 0) {
                    nazivstring += "\n--NISTA--";
                }
            }
            nazivstring += (otvorena.korpa[i].napomene) ? ("\nNapomene: \n" + otvorena.korpa[i].napomene) : ("");
            td2.innerText = nazivstring;
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = otvorena.korpa[i].ukupnacena;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);
        }

        if (otvorena.brojMesta == -1) {
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = "*";
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            td2.innerText = "Dostava";
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = otvorena.cenadostave;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);
        }


        if (otvorena.kupon) {
            let stavkatr = document.createElement("tr");
            let td1 = document.createElement("td");
            td1.className = "quantity";
            td1.innerText = 1;
            stavkatr.appendChild(td1);
            let td2 = document.createElement("td");
            td2.className = "description";
            let nazivstring = otvorena.kupon.tekst;
            td2.innerText = nazivstring;
            stavkatr.appendChild(td2);
            let td3 = document.createElement("td");
            td3.className = "price";
            td3.innerText = "-" + otvorena.kupon.vrednost;
            stavkatr.appendChild(td3);
            tbd.appendChild(stavkatr);

            ukupnacenasvega -= otvorena.kupon.vrednost;
            if (ukupnacenasvega < 0) {
                ukupnacenasvega = 0;
            }
        }
        let ukupnotr = document.createElement("tr");
        let td1u = document.createElement("td");
        td1u.className = "quantity";
        td1u.innerText = "";
        ukupnotr.appendChild(td1u);
        let td2u = document.createElement("td");
        td2u.className = "description";
        td2u.innerText = "UKUPNO";
        ukupnotr.appendChild(td2u);
        let td3u = document.createElement("td");
        td3u.className = "price";
        td3u.innerText = ukupnacenasvega + (otvorena.brojMesta == -1 ? otvorena.cenadostave : 0);
        ukupnotr.appendChild(td3u);

        tbd.appendChild(ukupnotr);
        tabela.appendChild(tbd);

        mywindow.document.write(`<!DOCTYPE html>
<html lang="en">

<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<meta http-equiv="X-UA-Compatible" content="ie=edge">
<link rel="stylesheet" href="style.css">
<title>Racun</title>
<style>
    html{
        margin: 0;
    }
    body{
        margin: 0;
    }
    * {
        font-size: 28px;
        font-family: 'Helvetica';
    }

    td,
    th,
    tr,
    table {
        width: 100%;
        word-wrap: break-word;
        border-top: 1px solid black;
        border-collapse: collapse;
    }

    td.description,
    th.description {
        width: 95px;
        max-width: 95px;
    }

    td.quantity,
    th.quantity {
        width: 20px;
        max-width: 20px;
        word-break: break-all;
    }

    td.price,
    th.price {
        width: 40px;
        max-width: 40px;
        word-break: break-all;
    }

    .centered {
        text-align: center;
        align-content: center;
    }

    .ticket {
        width: 400px;
        max-width: 400px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

    @media print {

        .hidden-print,
        .hidden-print * {
            display: none !important;
        }
    }
</style>
</head>

<body>
<div class="ticket">
    <p class="centered">NARUDZBINA
        <br>`+ "Sifra: " + otvorena.sifra + `
        <br>`+ stringvreme + `
        <br>`+ (otvorena.brojMesta == 0 ? "Za poneti" : (otvorena.brojMesta == -1 ? ("Dostava na adresu " + otvorena.adresa) : "Za ovde")) + `
    </p>
    `+
            tabela.outerHTML
            + `
</div>
</body>

</html>`);

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid) {
            //window.location = 'http://android.davidwalsh.name';
            mywindow.print();
            //mywindow.print();
            //mywindow.print();
            //mywindow.close();
        }
        else {
            mywindow.print();
            mywindow.close();
        }

        return true;
    }
    getSatiMinutiStringDo(minuti) {
        return (minuti > 59 ? (Math.floor(minuti / 60) + "h ") : "") + (minuti % 60) + "min";
    }
    getMinutiDo(vreme) {
        let rok = vreme.getTime() + 59999;
        return Math.floor((rok - new Date().getTime()) / 60000);
    }
    vremeNarudzbine(_idNarudzbine) {
        let self = this;
        if (!_idNarudzbine || _idNarudzbine == self.kesirana._id) return self.kesirana;
        let narudzbina = this.getSkorijaNarudzbina(_idNarudzbine);
        if (!narudzbina) return self.kesirana;
        self.kesirana._id = _idNarudzbine;
        if (narudzbina.putovanje && !narudzbina.vremesakupljanja) {
            self.kesirana.tip = 2;
            let datum = new Date(narudzbina.vreme_narudzbine + 1000 * narudzbina.putovanje.sekunde);
            let sati = datum.getHours();
            let minuti = datum.getMinutes();
            self.kesirana.string = sati + ":" + (minuti < 10 ? ("0" + minuti) : minuti);
            datum = new Date(datum.getTime() - datum.getTime() % 60000);
            self.kesirana.vreme = datum;
        }
        else if (narudzbina.vremesakupljanja) {
            self.kesirana.tip = 1;
            self.kesirana.string = narudzbina.vremesakupljanja;
            let sati = parseInt(narudzbina.vremesakupljanja.split(":")[0]);
            let minuti = parseInt(narudzbina.vremesakupljanja.split(":")[1]);
            let sadminuti = new Date().getMinutes();
            let sadsati = new Date().getHours();
            let dominuta = minuti - sadminuti;
            if (dominuta < 0) {
                dominuta += 60;
                sati--;
            }
            let dosati = (24 + sati - sadsati) % 24;
            let ciljtime = new Date().getTime() + dosati * 3600000 + dominuta * 60000;
            ciljtime -= ciljtime % 60000;
            self.kesirana.vreme = new Date(ciljtime);
            //Izracunati vreme
        }
        else {
            self.kesirana.tip = 0;
            self.kesirana.string = "";
            self.kesirana.vreme = new Date();
        }
        return self.kesirana;
    }
    componentDidMount() {
        let self = this;
        window.addEventListener('resize', () => {
            if (!self.state.rotiraj) {
                if ((window.innerHeight > window.innerWidth) != self.state.isMobile) {
                    self.setState({ isMobile: window.innerHeight > window.innerWidth });
                }
            }
            else {
                if ((window.innerHeight > window.innerWidth) == self.state.isMobile) {
                    self.setState({ isMobile: !(window.innerHeight > window.innerWidth) });
                }
            }
        })
        setInterval(function () {
            //console.log("Uso");
            if (self.baciozvon) {
                //console.log("Vec zvonio skoro");
                self.baciozvon = false;
                return;
            }
            else {
                //console.log("Gledam");
                let tz = false;
                for (let i in self.narudzbine) {
                    if ((self.narudzbine[i].status == "neprihvacena" && self.narudzbine[i].poruke.niz.length == 0) || (!self.narudzbine[i].poruke.restoranseen && (self.narudzbine[i].status == "spremna" || self.narudzbine[i].status == "priprema" || self.narudzbine[i].status == "neprihvacena"))) {
                        //console.log("Nasao");
                        tz = true;
                    }
                }
                if (tz)
                    self.pustizvuk();

            }

        }, 30000);
        global.socket.emit("zatraziListuMenijaIRushPostavkiRestoran");
        global.socket.once("odgovorNaTrazenjeListeMenijaIRushPostavkiRestoran", function (podaci) {
            if (podaci.uspeh) {
                self.menijiAdmin = podaci.menijiAdmin;
                self.menijiKorisnik = podaci.menijiKorisnik;
                self.meniUUpotrebi = podaci.meniUUpotrebi;
                self.setState({ blokiraniKorisnici: podaci.blokiraniKorisnici });
                self.setState({ rushPostavke: podaci.rushmode, rushPoruka: podaci.rushmode.porukakorisnicima, rushAkcija: podaci.rushmode.akcija, rushMode: podaci.rushmode.ukljucen });
                global.socket.on("izmenjeneRushPostavke", function (podaci) {
                    if (podaci.uspeh) {
                        alert("Uspesna izmena rush postavki. ");
                        self.setState({ rushPostavke: podaci.rushmode, rushPoruka: podaci.rushmode.porukakorisnicima, rushAkcija: podaci.rushmode.akcija, rushMode: podaci.rushmode.ukljucen, porukakorisniku: podaci.rushmode.porukakorisniku });
                    }
                    else if (podaci.drugi) {
                        alert("Drugi klijent je izmenio rush postavke. ");
                        self.setState({ rushPostavke: podaci.rushmode, rushPoruka: podaci.rushmode.porukakorisnicima, rushAkcija: podaci.rushmode.akcija, rushMode: podaci.rushmode.ukljucen, porukakorisniku: podaci.rushmode.porukakorisniku });
                    }
                    else {
                        alert("Greska pri izmeni rush postavki. Molimo pokusajte ponovo. ");
                    }
                });
                global.socket.emit("zatraziMeniRestoran", { _id: podaci.meniUUpotrebi });
                global.socket.once("odgovorNaTrazenjeMenijaRestoran", function (podaci) {
                    self.meni = podaci.meni;
                    self.setState({ nedostupno: podaci.meni.nedostupno })
                    global.socket.emit("zatraziListuNarudzbina", { max: 50 });
                    global.socket.once("stiglaListaNarudzbina", function (podaci) {
                        if (podaci.uspeh) {
                            self.narudzbine = podaci.narudzbine;
                            self.obradiDobijeneNarudzbine(self.narudzbine);
                            self.setState({ trenutnoPrikazana: {} });
                            global.socket.on("novaNarudzbinaKorisnika", function (podaci) {
                                if (!self.handlujBlok(self.state.blokiraniKorisnici, podaci.narudzbina))
                                    self.handlujRushMode(self.state.rushPostavke, podaci.narudzbina);
                                if (self.trebazvuk) {
                                    self.notif.loop = true;
                                    self.pustizvuk();
                                    if (self.state.megasmor === null)
                                        self.setState({ megasmor: setInterval(self.lightShow, 200), tekstObavestenja: "Imate novu narudžbinu" });
                                    else {
                                        self.setState({ tekstObavestenja: "Imate novu narudžbinu" });
                                    }
                                    self.baciozvon = true;
                                }
                                self.obradiNarudzbinu(podaci.narudzbina)
                                self.narudzbine.push(podaci.narudzbina);
                                self.setState({ brojac: self.state.brojac + 1 })
                            });
                            global.socket.on("izmenaPodatakaOPorudzbiniKorisnika", function (podaci) {
                                if (self.trebazvuk) {
                                    self.notif.loop = true;
                                    self.pustizvuk();
                                    if (self.state.megasmor === null)
                                        self.setState({ megasmor: setInterval(self.lightShow, 200), tekstObavestenja: "Narudžbina otkazana" });
                                    else {
                                        self.setState({ tekstObavestenja: "Narudžbina otkazana" });
                                    }
                                    self.baciozvon = true;
                                }
                                //console.log("EVO JE PODNAR")
                                //console.log(podaci.narudzbina)
                                self.obradiNarudzbinu(podaci.narudzbina);
                                if (self.state.trenutnoPrikazana._id == podaci.narudzbina._id) {
                                    if (podaci.narudzbina.status == "odbijena" || podaci.narudzbina.status == "otkazana" || podaci.narudzbina.status == "zavrsena" || podaci.narudzbina.status == "blokirana") {
                                        self.setState({ trenutnoPrikazana: {} })
                                    }
                                    else {
                                        self.setState({ trenutnoPrikazana: podaci.narudzbina });
                                    }
                                }
                                let narudzbina = self.getSkorijaNarudzbina(podaci.narudzbina._id);
                                if (!narudzbina) {
                                    self.narudzbine.push(podaci.narudzbina);
                                    self.setState({ brojac: self.state.brojac + 1 })
                                    return;
                                }
                                for (let i in podaci.narudzbina) {
                                    narudzbina[i] = podaci.narudzbina[i];
                                }
                                self.setState({ brojac: self.state.brojac + 1 })
                                return;
                            })
                            global.socket.on("novaPoruka", function (podaci) {
                                if (self.trebazvuk) {
                                    self.notif.loop = true;
                                    self.pustizvuk();
                                    if (self.state.megasmor === null)
                                        self.setState({ megasmor: setInterval(self.lightShow, 200), tekstObavestenja: "Imate novu poruku" });
                                    else {
                                        self.setState({ tekstObavestenja: "Imate novu poruku" });
                                    }
                                    self.baciozvon = true;
                                }
                                for (let i in self.narudzbine) {
                                    if (self.narudzbine[i]._id == podaci._id) {
                                        if (self.state.poruka && self.otvNarudzbinaPoruke._id == podaci._id) {
                                            global.socket.emit("sinuj", { _id: podaci._id });
                                            global.socket.once("uspesnoSinovao", function () {
                                                for (let j in self.narudzbine) {
                                                    if (self.narudzbine[j]._id == podaci._id) {
                                                        self.narudzbine[j].poruke.restoranseen = true;
                                                        self.setState({ brojac: self.state.brojac + 1 });
                                                    }
                                                }
                                            })
                                            self.scrollToBottom();
                                        }
                                        self.narudzbine[i].poruke = podaci.poruke;
                                        self.setState({ brojac: self.state.brojac + 1 });
                                    }
                                }
                                //PRIKAZ ZA NOVU PORUKU;, obavestenje restorana
                            })
                        }
                    });
                })
            }
            else {

            }
        });

    }
    blokiraj = (korisnik) => {
        this.pauzirajZvuk();
        global.socket.emit("blokirajKorisnika", { korisnik: korisnik });
        this.state.blokiraniKorisnici[korisnik] = true;
        this.setState({ blokiraniKorisnici: this.state.blokiraniKorisnici })
    }
    odblokiraj = (korisnik) => {
        this.pauzirajZvuk();
        global.socket.emit("odblokirajKorisnika", { korisnik: korisnik });
        delete this.state.blokiraniKorisnici[korisnik];
        this.setState({ blokiraniKorisnici: this.state.blokiraniKorisnici })
    }
    prihvati = (_id) => {
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: _id, status: "priprema" });
        this.setState({ idprihvacene: _id });
    }
    spremi = (_id) => {
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: _id, status: "spremna" });
    }
    odbij = (_id) => {
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: _id, status: "odbijena" });

    }
    otkazi = (_id) => {
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: _id, status: "otkazana" });

    }
    zavrsi = (_id) => {
        this.pauzirajZvuk();
        global.socket.emit("izmeniPorudzbinu", { _id: _id, status: "zavrsena" });

    }
    ucitajPoruke = (_id) => {
        let self = this;
        return new Promise((resolve, reject) => {
            for (let i in self.narudzbine) {
                if (self.narudzbine[i]._id == _id) {
                    self.otvNarudzbinaPoruke = self.narudzbine[i];

                }
            }
            resolve(true);
        })
    }
    prikazi = (_id, br) => {
        let self = this;
        if (br == 0) {
            this.setState({ poruka: true, trenutnoPrikazana: {} }, function () {
                self.scrollToBottom();
            })

        }
        else
            for (let i in this.narudzbine) {
                if (this.narudzbine[i]._id == _id) {
                    this.setState({ trenutnoPrikazana: this.narudzbine[i] })
                    return;
                }
            }
        this.setState({ trenutnoPrikazana: {} })
    }

    vratiStavke = () => {
        let rezultat = []

        for (let i in this.meni.kategorije) {
            for (let j in this.meni.kategorije[i].stavke) {
                rezultat.push(this.meni.kategorije[i].stavke[j])
            }
        }
        return rezultat
    }

    vratiOpcije = () => {
        let trenStavke = this.vratiStavke();
        let rezultat = []
        for (let i in trenStavke) {
            for (let j in trenStavke[i].grupeopcija) {
                for (let k in trenStavke[i].grupeopcija[j].opcije) {
                    rezultat.push(trenStavke[i].grupeopcija[j].opcije[k])
                }
            }
        }
        return rezultat;
    }

    obradiDostupnost = (id) => {
        let noviNedostupni = this.state.nedostupno
        if (this.state.nedostupno[id])
            delete noviNedostupni[id]
        else
            noviNedostupni[id] = true
        this.setState({ nedostupno: noviNedostupni });
    }

    lightShow() {
        glupis.self.setState({ bojals: "#" + Math.floor(Math.random() * 16777215).toString(16) });
        //self.setState({ bojals: "#" + Math.floor(Math.random() * 16777215).toString(16) });
    }
    getSkorijaNarudzbina(_id) {
        let self = this;
        for (let i = self.narudzbine.length - 1; i >= 0; i--) {
            if (self.narudzbine[i]._id == _id) {
                return self.narudzbine[i];
            }
        }
        return null;
    }
    narudzbinaTajmer(minuti, narudzbina, novi) {
        let self = this
        let nar = self.getSkorijaNarudzbina(narudzbina);
        if (nar.calback) {
            clearInterval(nar.calback)
        }
        nar.brojac = minuti - 1;
        if (!novi)
            global.cookies.set(nar._id, new Date().getTime() + 1000 * 60 * (nar.brojac + 1), { path: '/', expires: (new Date(new Date().getTime() + 1000 * 60 * (nar.brojac + 1))) })
        nar.calback = setInterval(function () {
            if (nar.brojac <= 0) {
                clearInterval(nar.calback)
                delete nar.brojac;
                delete nar.calback;
            }
            nar.brojac--;
            self.setState({ brojac: self.state.brojac + 1 });
        }, 60000);
    }
    odrediVreme(minuti, vreme) {
        let self = this
        self.pauzirajZvuk();
        this.narudzbinaTajmer(minuti, self.state.idprihvacene)
        global.socket.emit("posaljiPoruku", { tekst: (vreme && (minuti > 1)) ? self.templejtNaVreme(vreme) : self.templejtPrihvacenePoruke(minuti), _id: self.state.idprihvacene })
        self.setState({ idprihvacene: null, drugovreme: null });
    }
    render() {
        let self = this;
        return self.state.megasmor !== null ? (<LightShow color={self.state.bojals} tekst={self.state.tekstObavestenja} onClick={() => {
            clearInterval(self.state.megasmor);
            self.notif.loop = false;
            self.setState({ megasmor: null, inicijalnimodal: false });
        }}></LightShow >) : (
            !self.state.isMobile ?
                <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
                    <Container style={stylesDesktop.instrukcije}>
                        <Container style={{ ...stylesDesktop.rush, flexDirection: self.state.isMobile ? 'row' : 'column', height: self.state.isMobile ? '3vh' : '11.5vh' }}>Rush mod
                            <Switch checked={this.state.rushMode} onChange={() => {
                                let rp = { ukljucen: !this.state.rushMode, porukakorisnicima: this.state.rushPoruka, akcija: this.state.rushAkcija, porukakorisniku: this.state.porukakorisniku };
                                if (!this.state.rushMode) {
                                    this.setState({ rushOtvoren: true, rushMode: true });
                                }
                                else {
                                    global.socket.emit("sacuvajRushPostavke", { rushmode: rp });
                                }
                            }}></Switch>
                        </Container>

                        <Container style={{ ...stylesDesktop.rush, flexDirection: self.state.isMobile ? 'row' : 'column', height: self.state.isMobile ? '3vh' : '11.5vh' }}>
                            <a style={{ marginRight: self.state.isMobile ? 10 : 0 }}>Gotove narudžbine</a>
                            <Switch checked={this.state.istorijaOtvorena} onChange={() => {
                                this.setState({ istorijaOtvorena: !this.state.istorijaOtvorena })
                            }}></Switch>
                        </Container>
                        <Button style={{ margin: 5, borderRadius: 10, whiteSpace: "nowrap", width: "max-content", padding: 5, color: 'white' }}
                            onClick={() => { this.setState({ dostupnostModal: true }) }}
                        >
                            <a>Dostupnost stavki</a>
                        </Button>
                        <Container style={{ ...stylesDesktop.instrukcijaKontis, backgroundColor: '#b5e48c', margin: 5, borderRadius: 10, whiteSpace: "nowrap", width: "max-content", padding: 5 }} >
                            <a>Nova</a>
                        </Container>
                        <Container style={{ ...stylesDesktop.instrukcijaKontis, backgroundColor: '#fcf56a', margin: 5, borderRadius: 10, whiteSpace: "nowrap", width: "max-content", padding: 5 }} >
                            <a>Prihvacena</a>
                        </Container>
                        <Container onClick={() => {
                            if (self.state.rotiraj) {
                                if ((window.innerHeight > window.innerWidth) != self.state.isMobile) {
                                    self.setState({ isMobile: window.innerHeight > window.innerWidth });
                                }
                            }
                            else {
                                if ((window.innerHeight > window.innerWidth) == self.state.isMobile) {
                                    self.setState({ isMobile: !(window.innerHeight > window.innerWidth) });
                                }
                            }
                            self.setState({ rotiraj: !self.state.rotiraj });
                        }} style={{ minWidth: 35, marginRight: "2%", minHeight: 35, maxWidth: 35, maxHeight: 35, borderRadius: 50, backgroundColor: '#bbbbbb', padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 48 48">
                                <path d="M0 0h48v48h-48z" fill="none" />
                                <path d="M32.97 5.03c6.53 3.1 11.22 9.45 11.93 16.97h3c-1.02-12.32-11.32-22-23.9-22-.45 0-.88.04-1.33.07l7.63 7.63 2.67-2.67zm-12.51-1.54c-1.17-1.17-3.07-1.17-4.24 0l-12.73 12.73c-1.17 1.17-1.17 3.07 0 4.24l24.04 24.04c1.17 1.17 3.07 1.17 4.24 0l12.73-12.73c1.17-1.17 1.17-3.07 0-4.24l-24.04-24.04zm9.2 38.89l-24.05-24.04 12.73-12.73 24.04 24.04-12.72 12.73zm-14.63.59c-6.53-3.1-11.22-9.45-11.93-16.97h-3c1.02 12.32 11.32 22 23.9 22 .45 0 .88-.04 1.33-.07l-7.63-7.63-2.67 2.67z" />
                            </svg></Container>
                        <Button variant="danger"
                            onClick={() => { this.setState({ radi: false }) }}
                        > {this.state.radi ? null : <Redirect to='/meni'></Redirect>}
                            Završite sesiju
                        </Button>

                    </Container>
                    <div style={{ ...stylesDesktop.container, overflow: "hidden" }}>

                        <BootstrapModal show={self.state.idprihvacene}
                            onHide={() => {
                                self.setState({ idprihvacene: null });
                            }}>
                            <BootstrapModal.Header>
                                <BootstrapModal.Title>Vreme čekanja</BootstrapModal.Title>
                            </BootstrapModal.Header>
                            <BootstrapModal.Body>
                                {(self.vremeNarudzbine(self.state.idprihvacene).tip > 0) ? <div><div style={{ textAlign: "center" }}>{(self.vremeNarudzbine(self.state.idprihvacene).tip == 1) ? "Na" : "Procenjeno"} vreme: </div>
                                    <Button variant="secondary" style={{ margin: 2, width: '100%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(self.getMinutiDo(self.vremeNarudzbine(self.state.idprihvacene).vreme), (self.vremeNarudzbine(self.state.idprihvacene).tip == 1) ? self.vremeNarudzbine(self.state.idprihvacene).string : false)
                                    }}>{(self.state.idprihvacene && self.vremeNarudzbine(self.state.idprihvacene)) ? (self.vremeNarudzbine(self.state.idprihvacene).string + " (" + self.getSatiMinutiStringDo(self.getMinutiDo(self.vremeNarudzbine(self.state.idprihvacene).vreme)) + ")") : ""}</Button>
                                </div> : null}
                                <div style={{ textAlign: "center" }}>Drugo vreme:</div>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(1)
                                    }}>1 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(2)
                                    }}>2 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(3)
                                    }}>3 min</Button>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(5)
                                    }}>5 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(10)
                                    }}>10 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(15)
                                    }}>15 min</Button>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(20)
                                    }}>20 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(30)
                                    }}>30 min</Button>
                                </div>

                                <div style={{ textAlign: "center" }}>
                                    <input style={{ margin: 1 }} type="number" placeholder="Unesite vreme (min)" value={self.state.drugovreme} onChange={e => {
                                        self.setState({ drugovreme: e.target.value });
                                    }}></input>
                                    <button onClick={() => {
                                        self.odrediVreme(self.state.drugovreme)
                                    }} style={{ margin: 1 }}>OK</button>
                                </div>
                            </BootstrapModal.Body>
                        </BootstrapModal>
                        <BootstrapModal onClick={() => {
                            self.setState({ inicijalnimodal: false });
                        }} show={self.state.inicijalnimodal} onHide={() => {
                            self.setState({ inicijalnimodal: false });
                        }}>
                            <BootstrapModal.Header>
                                <BootstrapModal.Title>Dobrodošli!</BootstrapModal.Title>
                            </BootstrapModal.Header>
                            <BootstrapModal.Body>
                                Kliknite bilo gde da biste nastavili
                            </BootstrapModal.Body>
                        </BootstrapModal>
                        <Modal
                            style={{
                                overlay: {
                                    display: 'flex',
                                    alignSelf: 'center'
                                    , alignItems: 'center', justifyContent: "center", flexDirection: 'column'
                                },
                                content: {
                                    display: 'flex', backgroundColor: 'whitesmoke',
                                    flexDirection: 'column', justifyContent: 'center', textAlign: 'center', borderRadius: 10, padding: 3, bordercolor: 'black'
                                }
                            }}
                            isOpen={this.state.poruka}
                            contentLabel="Example Modal"
                            className={`shadow p`}
                        >

                            <div style={{ justifyContent: 'space-between', width: "40vw", height: "85vh", backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <div style={{ display: "flex", flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                                        {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                            self.pauzirajZvuk();
                                            self.narudzbinaTajmer(5, self.otvNarudzbinaPoruke._id)
                                            global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(5), _id: self.otvNarudzbinaPoruke._id })
                                        }}>5 min</Button> : null}
                                        {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                            self.pauzirajZvuk();
                                            self.narudzbinaTajmer(10, self.otvNarudzbinaPoruke._id)
                                            global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(10), _id: self.otvNarudzbinaPoruke._id })
                                        }}>10 min</Button> : null}
                                        {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                            self.pauzirajZvuk();
                                            self.narudzbinaTajmer(15, self.otvNarudzbinaPoruke._id)
                                            global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(15), _id: self.otvNarudzbinaPoruke._id })
                                        }}>15 min</Button> : null}
                                        {self.isNumeric(self.state.tekstPoruke.replace(/ /g, "")) ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                            self.pauzirajZvuk();
                                            self.narudzbinaTajmer(parseInt(self.state.tekstPoruke.replace(/ /g, "")), self.otvNarudzbinaPoruke._id)
                                            global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(parseInt(self.state.tekstPoruke.replace(/ /g, ""))), _id: self.otvNarudzbinaPoruke._id })
                                            self.setState({ tekstPoruke: "" });
                                        }}>{parseInt(self.state.tekstPoruke.replace(/ /g, ""))} min</Button> : null}
                                    </div>

                                </div>
                                <Button variant="warning" style={{ marginTop: 10, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => { this.setState({ poruka: false }) }}>Zatvori cet</Button>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: "flex-start", alignItems: "baseline", padding: 10, overflow: "auto" }}>{
                                    self.otvNarudzbinaPoruke.poruke.niz.map((item) =>
                                        <a style={{ display: "flex", alignSelf: global.restoran._id == item.posiljalac ? 'flex-start' : 'flex-end', fontSize: self.state.isMobile ? 15 : 20 }}>{item.tekst}</a>)}

                                    <div style={{ float: "left", clear: "both" }}
                                        ref={(el) => {
                                            self.dnoporuka = el;
                                            self.scrollToBottom();
                                        }}>
                                    </div>
                                </div>
                                <div style={{ float: "left", clear: "both" }}
                                    ref={(el) => {
                                        self.dnoporuka = el;
                                        self.scrollToBottom();
                                    }}>
                                </div>
                                <input
                                    style={{ display: 'flex', alignSelf: "flex-end" }}
                                    className="form-control"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            self.pauzirajZvuk();
                                            global.socket.emit("posaljiPoruku", { tekst: self.state.tekstPoruke, _id: self.otvNarudzbinaPoruke._id })
                                            self.setState({ tekstPoruke: "" });
                                        }
                                    }} type="text" value={self.state.tekstPoruke} onSubmit={() => { }} onChange={(event) => self.setState({ tekstPoruke: event.target.value })}></input>

                            </div>
                        </Modal>

                        <Modal
                            //rushPostavkeModal
                            style={{
                                overlay: {
                                    display: 'flex',
                                    alignSelf: 'center'
                                    , alignItems: 'center', justifyContent: "center", flexDirection: 'column'
                                },
                                content: {
                                    display: 'flex', backgroundColor: 'whitesmoke',
                                    flexDirection: 'column', justifyContent: 'center', textAlign: 'center', borderRadius: 10,
                                }
                            }}
                            isOpen={(this.state.rushOtvoren && this.state.rushMode)}
                            contentLabel="Example Modal"
                            className={`shadow p`}
                        >
                            <Container style={{ width: '50vw', height: '30vh', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Select style={{ marginBottom: 10, width: '40vw' }} options={self.rushAkcije} values={self.rushAkcije.filter(e => e.label == this.state.rushAkcija)} onChange={(e) => { this.setState({ rushAkcija: e[0].label }) }}></Select>
                                <input
                                    value={self.state.rushPoruka}
                                    onChange={(e) => { this.setState({ rushPoruka: e.target.value }) }}
                                    id="rushPoruka" class="form-control" placeholder="Poruka korisnicima" style={{ width: '40vw', height: '40%', marginBottom: 10 }} ></input>
                                <input
                                    disabled={self.state.rushAkcija != "poruka"}
                                    value={self.state.porukakorisniku}
                                    onChange={(e) => { this.setState({ porukakorisniku: e.target.value }) }}
                                    id="rushPorukaKorisniku" class="form-control" placeholder="Poruka korisniku" style={{ width: '40vw', height: '40%', marginBottom: 10 }} ></input>
                                <Button
                                    onClick={() => {
                                        let rp = { ukljucen: this.state.rushMode, porukakorisnicima: this.state.rushPoruka, akcija: this.state.rushAkcija, porukakorisniku: this.state.porukakorisniku };
                                        this.setState({ rushOtvoren: false, rushMode: this.state.rushPostavke.ukljucen, rushPoruka: this.state.rushPostavke.porukakorisnicima, porukakorisniku: this.state.rushPostavke.porukakorisniku })
                                        global.socket.emit("sacuvajRushPostavke", { rushmode: rp });
                                    }}
                                    variant="outline-success" style={{ width: '90%', height: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>Sacuvaj rush postavke</Button>
                            </Container>
                        </Modal>
                        {/*dostupnost*/}
                        <BootstrapModal

                            show={self.state.dostupnostModal} onHide={() => {
                                self.setState({ dostupnostModal: false });
                            }}
                        >
                            <BootstrapModal.Title>Dostupnost stavki</BootstrapModal.Title>
                            <BootstrapModal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ width: '100%', height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%' }}>
                                        <input className="form-control" style={{ width: '70%', height: '' }}
                                            value={this.state.dostupnostInputText}
                                            onChange={e => {
                                                self.setState({ dostupnostInputText: e.target.value })
                                            }}
                                        />
                                        <Button
                                            style={{ width: '20%', marginLeft: 10 }}
                                            onClick={() => { this.setState({ dostupnostSearchText: this.state.dostupnostInputText }) }}                                       >
                                            Pretraži
                                        </Button>
                                    </div>
                                    {this.state.dostupnostSearchText.length > 0 ?
                                        <div style={{ height: "100%", overflow: "auto" }}>
                                            <div style={{ width: '100%' }}>
                                                {self.meni.kategorije.filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLowerCase())).map((item, index) =>
                                                    <div style={{ width: '100%' }}>
                                                        {index == 0 ? <a>Kategorije</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                            <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                                onClick={() => {
                                                                    this.obradiDostupnost(item.identifikator)
                                                                }}
                                                            >
                                                                {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                            </Button>
                                                        </Container>
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                {self.vratiStavke().filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLowerCase())).map((item, index) =>
                                                    <div style={{ width: '100%' }}>
                                                        {index == 0 ? <a>Stavke</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                        </Container>
                                                        <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                            onClick={() => {
                                                                this.obradiDostupnost(item.identifikator)
                                                            }}
                                                        >
                                                            {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                        </Button>
                                                    </div>
                                                )}

                                            </div>
                                            <div>
                                                {self.vratiOpcije().filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLowerCase())).map((item, index) =>
                                                    <div style={{ width: '100%' }}>
                                                        {index == 0 ? <a>Opcije</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                        </Container>
                                                        <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                            onClick={() => {
                                                                this.obradiDostupnost(item.identifikator)
                                                            }}
                                                        >
                                                            {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                        </Button>
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                        :
                                        <a>Pretražite stavke ...</a>}
                                </div>
                                <Button variant="success" style={{ alignSelf: 'center', width: "80%" }}
                                    onClick={() => {
                                        global.socket.emit("izmeniNedostupno", { _id: self.meni._id, nedostupno: self.meni.nedostupno });
                                        global.socket.once("odgovorNaIzmenuNedostupnog", function (rezultat) {
                                            if (!rezultat) {
                                                alert("Neuspesno")
                                                return;
                                            }
                                            alert("Uspesno")
                                        })
                                    }}
                                >Sacuvaj</Button>
                            </BootstrapModal.Body>
                        </BootstrapModal>

                        <Container style={{ ...stylesDesktop.podContainer, overflow: "auto" }}>
                            {this.narudzbine.filter(item => item.status == "neprihvacena").map(item => <NovaNarudzbina key={item._id + item.status + item.brojac} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} prihvati={this.prihvati} odbij={this.odbij} props={item} stampaj={this.stampaj}></NovaNarudzbina>)}
                        </Container>
                        <Container style={{ ...stylesDesktop.podContainer, overflow: "auto" }}>

                            {this.narudzbine.filter(item => (item.status == "priprema" || item.status == "spremna")).map(item => <PrihvacenaNarudzbina key={item._id + item.status + item.brojac} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} spremi={this.spremi} zavrsi={this.zavrsi} otkazi={this.otkazi} props={item} stampaj={this.stampaj}></PrihvacenaNarudzbina>)}

                        </Container>
                        <Container style={{ ...stylesDesktop.podContainer, overflow: "auto" }}>

                            {(this.narudzbine.filter(item => (item.status == "otkazana" || item.status == "odbijena" || item.status == "zavrsena" || item.status == "blokirana")).reverse().map(item => <GotovaNarudzbina key={item._id + item.status} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} blokiraj={this.blokiraj} odblokiraj={this.odblokiraj} props={item} blokiraniKorisnici={self.state.blokiraniKorisnici} stampaj={this.stampaj}></GotovaNarudzbina>))}
                            {/*Object.keys(this.state.trenutnoPrikazana).length === 0 ? null : <TrenutnaPorudzbina prikazi={this.prikazi} spremi={this.spremi} zavrsi={this.zavrsi} otkazi={this.otkazi} narudzbina={this.state.trenutnoPrikazana} prihvati={this.prihvati} odbij={this.odbij}></TrenutnaPorudzbina>*/}
                        </Container>


                    </div >

                </div>
                :
                (Object.keys(this.state.trenutnoPrikazana).length === 0 ?
                    <div>

                        <BootstrapModal onClick={() => {
                            self.setState({ inicijalnimodal: false });
                        }} show={self.state.inicijalnimodal} onHide={() => {
                            self.setState({ inicijalnimodal: false });
                        }}>

                            <BootstrapModal.Header>
                                <BootstrapModal.Title>Dobrodošli!</BootstrapModal.Title>
                            </BootstrapModal.Header>
                            <BootstrapModal.Body>
                                Kliknite bilo gde da biste nastavili
                            </BootstrapModal.Body>
                        </BootstrapModal>
                        <BootstrapModal show={self.state.idprihvacene}
                            onHide={() => {
                                self.setState({ idprihvacene: null });
                            }}>
                            <BootstrapModal.Header>
                                <BootstrapModal.Title>Vreme čekanja</BootstrapModal.Title>
                            </BootstrapModal.Header>
                            <BootstrapModal.Body>
                                {(self.vremeNarudzbine(self.state.idprihvacene).tip > 0) ? <div><div style={{ textAlign: "center" }}>{(self.vremeNarudzbine(self.state.idprihvacene).tip == 1) ? "Na" : "Procenjeno"} vreme: </div>
                                    <Button variant="secondary" style={{ margin: 2, width: '100%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(self.getMinutiDo(self.vremeNarudzbine(self.state.idprihvacene).vreme), (self.vremeNarudzbine(self.state.idprihvacene).tip == 1) ? self.vremeNarudzbine(self.state.idprihvacene).string : false)
                                    }}>{(self.state.idprihvacene && self.vremeNarudzbine(self.state.idprihvacene)) ? (self.vremeNarudzbine(self.state.idprihvacene).string + " (" + self.getSatiMinutiStringDo(self.getMinutiDo(self.vremeNarudzbine(self.state.idprihvacene).vreme)) + ")") : ""}</Button>
                                </div> : null}
                                <div style={{ textAlign: "center" }}>Drugo vreme:</div>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(1)
                                    }}>1 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(2)
                                    }}>2 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(3)
                                    }}>3 min</Button>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>

                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(5)
                                    }}>5 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(10)
                                    }}>10 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(15)
                                    }}>15 min</Button>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 2 }}>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(20)
                                    }}>20 min</Button>
                                    <Button variant="secondary" style={{ margin: 2, width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.odrediVreme(30)
                                    }}>30 min</Button>
                                </div>

                                <div style={{ textAlign: "center" }}>
                                    <input style={{ margin: 1 }} type="number" placeholder="Unesite vreme (min)" value={self.state.drugovreme} onChange={e => {
                                        self.setState({ drugovreme: e.target.value });
                                    }}></input>
                                    <button onClick={() => {
                                        self.odrediVreme(self.state.drugovreme)
                                    }} style={{ margin: 1 }}>OK</button>
                                </div>
                            </BootstrapModal.Body>
                        </BootstrapModal>
                        <Modal

                            isOpen={this.state.poruka}
                            contentLabel="Example Modal"
                            className={`shadow p`}
                        >
                            <div style={{ width: "100vw", height: "70vh", backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                                <Button variant="warning" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => { this.setState({ poruka: false }) }}>Zatvori cet</Button>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: "flex-start", alignItems: "baseline", padding: 10, overflow: "auto" }}>{
                                    self.otvNarudzbinaPoruke.poruke.niz.map((item) =>
                                        <a style={{ display: "flex", alignSelf: global.restoran._id == item.posiljalac ? 'flex-start' : 'flex-end', fontSize: self.state.isMobile ? 15 : 20 }}>{item.tekst}</a>)}

                                    <div style={{ float: "left", clear: "both" }}
                                        ref={(el) => {
                                            self.dnoporuka = el;
                                            self.scrollToBottom();
                                        }}>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                                    {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.pauzirajZvuk();
                                        self.narudzbinaTajmer(5, self.otvNarudzbinaPoruke._id)
                                        global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(5), _id: self.otvNarudzbinaPoruke._id })
                                    }}>5 min</Button> : null}
                                    {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.pauzirajZvuk();
                                        self.narudzbinaTajmer(10, self.otvNarudzbinaPoruke._id)
                                        global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(10), _id: self.otvNarudzbinaPoruke._id })
                                    }}>10 min</Button> : null}
                                    {self.state.tekstPoruke.length == 0 ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.pauzirajZvuk();
                                        self.narudzbinaTajmer(15, self.otvNarudzbinaPoruke._id)
                                        global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(15), _id: self.otvNarudzbinaPoruke._id })
                                    }}>15 min</Button> : null}
                                    {self.isNumeric(self.state.tekstPoruke.replace(/ /g, "")) ? <Button variant="secondary" style={{ width: '80%', display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                        self.pauzirajZvuk();
                                        self.narudzbinaTajmer(parseInt(self.state.tekstPoruke.replace(/ /g, "")), self.otvNarudzbinaPoruke._id)
                                        global.socket.emit("posaljiPoruku", { tekst: self.templejtBrzePoruke(parseInt(self.state.tekstPoruke.replace(/ /g, ""))), _id: self.otvNarudzbinaPoruke._id })
                                        self.setState({ tekstPoruke: "" });
                                    }}>{parseInt(self.state.tekstPoruke.replace(/ /g, ""))} min</Button> : null}
                                </div>
                                <input
                                    style={{ display: 'flex', alignSelf: "flex-end", width: "90%", alignSelf: 'center', marginBottom: 10 }}
                                    className="form-control"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            self.pauzirajZvuk();
                                            global.socket.emit("posaljiPoruku", { tekst: self.state.tekstPoruke, _id: self.otvNarudzbinaPoruke._id })
                                            self.setState({ tekstPoruke: "" });
                                        }
                                    }} type="text" value={self.state.tekstPoruke} onSubmit={() => { }} onChange={(event) => self.setState({ tekstPoruke: event.target.value })}></input>                            </div>
                        </Modal >

                        <Modal
                            //rushPostavkeModal
                            style={{
                                overlay: {
                                    display: 'flex',
                                    alignSelf: 'center'
                                    , alignItems: 'center', justifyContent: "center", flexDirection: 'column'
                                },
                                content: {
                                    display: 'flex', backgroundColor: 'whitesmoke',
                                    flexDirection: 'column', justifyContent: 'center', textAlign: 'center', borderRadius: 10,
                                }
                            }}
                            isOpen={(this.state.rushOtvoren && this.state.rushMode)}
                            contentLabel="Example Modal"
                            className={`shadow p`}
                        >
                            <Container style={{ width: '80vw', height: '50vh', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Select style={{ marginBottom: 10, width: '70vw' }} options={self.rushAkcije} values={self.rushAkcije.filter(e => e.label == this.state.rushAkcija)} onChange={(e) => { this.setState({ rushAkcija: e[0].label }) }}></Select>
                                <input
                                    value={self.state.rushPoruka}
                                    onChange={(e) => { this.setState({ rushPoruka: e.target.value }) }}
                                    id="rushPoruka" class="form-control" placeholder="Poruka korisnicima" style={{ width: '90%', height: '40%', marginBottom: 10 }} ></input>
                                <input
                                    disabled={self.state.rushAkcija != "poruka"}
                                    value={self.state.porukakorisniku}
                                    onChange={(e) => { this.setState({ porukakorisniku: e.target.value }) }}
                                    id="rushPorukaKorisniku" class="form-control" placeholder="Poruka korisniku" style={{ width: '40vw', height: '40%', marginBottom: 10 }} ></input>
                                <Button
                                    onClick={() => {
                                        let rp = { ukljucen: this.state.rushMode, porukakorisnicima: this.state.rushPoruka, akcija: this.state.rushAkcija, porukakorisniku: this.state.porukakorisniku };
                                        this.setState({ rushOtvoren: false, rushMode: this.state.rushPostavke.ukljucen, rushPoruka: this.state.rushPostavke.porukakorisnicima, porukakorisniku: this.state.rushPostavke.porukakorisniku })
                                        global.socket.emit("sacuvajRushPostavke", { rushmode: rp });
                                    }}
                                    variant="outline-success" style={{ width: '90%', height: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>Sacuvaj rush postavke</Button>
                            </Container>
                        </Modal>
                        <BootstrapModal

                            show={self.state.dostupnostModal} onHide={() => {
                                self.setState({ dostupnostModal: false });
                            }}
                        >
                            <BootstrapModal.Title>Dostupnost stavki</BootstrapModal.Title>
                            <BootstrapModal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ width: '90%', height: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%' }}>
                                        <input className="form-control" style={{ width: '70%', height: '' }}
                                            value={this.state.dostupnostInputText}
                                            onChange={e => {
                                                self.setState({ dostupnostInputText: e.target.value })
                                            }}
                                        />
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            onClick={() => { this.setState({ dostupnostSearchText: this.state.dostupnostInputText }) }}                                       >
                                            Pretraži
                                        </Button>
                                    </div>
                                    {this.state.dostupnostSearchText.length > 0 ?
                                        <div style={{ height: "100%", overflow: "auto", width: "100%" }}>
                                            <div style={{ width: '100%' }}>
                                                {self.meni.kategorije.filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLocaleLowerCase())).map((item, index) =>
                                                    <div style={{ width: '90%', margin: 10 }}>
                                                        {index == 0 ? <a>Kategorije</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                            <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                                onClick={() => {
                                                                    this.obradiDostupnost(item.identifikator)
                                                                }}
                                                            >
                                                                {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                            </Button>
                                                        </Container>
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                {self.vratiStavke().filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLocaleLowerCase())).map((item, index) =>
                                                    <div style={{ width: '90%', margin: 10 }}>
                                                        {index == 0 ? <a>Stavke</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                        </Container>
                                                        <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                            onClick={() => {
                                                                this.obradiDostupnost(item.identifikator)
                                                            }}
                                                        >
                                                            {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                        </Button>
                                                    </div>
                                                )}

                                            </div>
                                            <div>
                                                {self.vratiOpcije().filter(item => item.naziv.toLowerCase().includes(this.state.dostupnostSearchText.toLocaleLowerCase())).map((item, index) =>
                                                    <div style={{ width: '100%', margin: 10 }}>
                                                        {index == 0 ? <a>Opcije</a> : null}
                                                        <Container
                                                            style={stylesDesktop.izbaciStavkuKontejner}
                                                        >
                                                            <a>{item.naziv}</a>
                                                        </Container>
                                                        <Button variant={self.state.nedostupno[item.identifikator] ? "danger" : "success"} style={stylesDesktop.izbaciStavkuDugme}
                                                            onClick={() => {
                                                                this.obradiDostupnost(item.identifikator)
                                                            }}
                                                        >
                                                            {!self.state.nedostupno[item.identifikator] ? " Oznaci nedostupnim" : "Oznaci dostupnim"}
                                                        </Button>
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                        :
                                        <a>Pretražite stavke ...</a>}
                                </div>
                                <Button variant="success" style={{ alignSelf: 'center', width: "80%" }}
                                    onClick={() => {
                                        global.socket.emit("izmeniNedostupno", { _id: self.meni._id, nedostupno: self.meni.nedostupno });
                                        global.socket.once("odgovorNaIzmenuNedostupnog", function (rezultat) {
                                            if (!rezultat) {
                                                alert("Neuspesno")
                                                return;
                                            }
                                            alert("Uspesno")
                                        })
                                    }}
                                >Sacuvaj</Button>
                            </BootstrapModal.Body>
                        </BootstrapModal>
                        <Container style={stylesDesktop.instrukcije}>

                            <span style={{ ...stylesDesktop.instrukcijaKontis, backgroundColor: '#b5e48c', margin: 5, padding: 5 }} >
                                <a>Nova</a>
                            </span>
                            <span style={{ ...stylesDesktop.instrukcijaKontis, backgroundColor: '#fcf56a', margin: 5, padding: 5 }} >
                                <a>Prihvacena</a>
                            </span>

                            <Button style={{ borderRadius: 5, height: "45%" }}
                                onClick={() => { this.setState({ dostupnostModal: true }) }}
                            >
                                Meni
                            </Button>
                            <Container onClick={() => {
                                if (self.state.rotiraj) {
                                    if ((window.innerHeight > window.innerWidth) != self.state.isMobile) {
                                        self.setState({ isMobile: window.innerHeight > window.innerWidth });
                                    }
                                }
                                else {
                                    if ((window.innerHeight > window.innerWidth) == self.state.isMobile) {
                                        self.setState({ isMobile: !(window.innerHeight > window.innerWidth) });
                                    }
                                }
                                self.setState({ rotiraj: !self.state.rotiraj });
                            }} style={{ minWidth: 35, marginRight: "2%", minHeight: 35, maxWidth: 35, maxHeight: 35, borderRadius: 50, backgroundColor: '#bbbbbb', padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 48 48">
                                    <path d="M0 0h48v48h-48z" fill="none" />
                                    <path d="M32.97 5.03c6.53 3.1 11.22 9.45 11.93 16.97h3c-1.02-12.32-11.32-22-23.9-22-.45 0-.88.04-1.33.07l7.63 7.63 2.67-2.67zm-12.51-1.54c-1.17-1.17-3.07-1.17-4.24 0l-12.73 12.73c-1.17 1.17-1.17 3.07 0 4.24l24.04 24.04c1.17 1.17 3.07 1.17 4.24 0l12.73-12.73c1.17-1.17 1.17-3.07 0-4.24l-24.04-24.04zm9.2 38.89l-24.05-24.04 12.73-12.73 24.04 24.04-12.72 12.73zm-14.63.59c-6.53-3.1-11.22-9.45-11.93-16.97h-3c1.02 12.32 11.32 22 23.9 22 .45 0 .88-.04 1.33-.07l-7.63-7.63-2.67 2.67z" />
                                </svg></Container>
                            <Button variant="danger"
                                style={{ marginRight: 1 }}
                                onClick={() => { this.setState({ radi: false }) }}
                            > {this.state.radi ? null : <Redirect to='/statistika'></Redirect>}
                                Odjavi se
                            </Button>
                        </Container>
                        <Container style={{ ...stylesDesktop.rush, flexDirection: self.state.isMobile ? 'row' : 'column', height: self.state.isMobile ? '3vh' : '11.5vh' }}>
                            <a style={{ marginRight: self.state.isMobile ? 10 : 0 }}>Rush postavke</a>
                            <Switch checked={this.state.rushMode} onChange={() => {
                                let rp = { ukljucen: !this.state.rushMode, porukakorisnicima: this.state.rushPoruka, akcija: this.state.rushAkcija, porukakorisniku: this.state.porukakorisniku };

                                if (!this.state.rushMode) {
                                    this.setState({ rushOtvoren: true, rushMode: true });
                                }
                                else {
                                    global.socket.emit("sacuvajRushPostavke", { rushmode: rp });
                                }
                            }}></Switch>
                        </Container>

                        <Container style={{ ...stylesDesktop.rush, flexDirection: self.state.isMobile ? 'row' : 'column', height: self.state.isMobile ? '3vh' : '11.5vh' }}>
                            <a style={{ marginRight: self.state.isMobile ? 10 : 0 }}>Gotove narudžbine</a>
                            <Switch checked={this.state.istorijaOtvorena} onChange={() => {
                                this.setState({ istorijaOtvorena: !this.state.istorijaOtvorena })
                            }}></Switch>
                        </Container>
                        {!this.state.istorijaOtvorena || (this.narudzbine.filter(item => (item.status == "otkazana" || item.status == "odbijena" || item.status == "zavrsena" || item.status == "blokirana")).reverse().map(item => <GotovaNarudzbina key={item._id + item.status} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} blokiraj={this.blokiraj} odblokiraj={this.odblokiraj} props={item} blokiraniKorisnici={self.state.blokiraniKorisnici} stampaj={this.stampaj}></GotovaNarudzbina>))}
                        {this.state.istorijaOtvorena || (this.narudzbine.filter(item => item.status == "neprihvacena").map(item => <NovaNarudzbina key={item._id + item.status} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} prihvati={this.prihvati} odbij={this.odbij} props={item} stampaj={this.stampaj}></NovaNarudzbina>))}
                        {this.state.istorijaOtvorena || (this.narudzbine.filter(item => (item.status == "priprema" || item.status == "spremna")).map(item => <PrihvacenaNarudzbina key={item._id + item.status} ucitajporuke={this.ucitajPoruke} prikazi={this.prikazi} spremi={this.spremi} zavrsi={this.zavrsi} otkazi={this.otkazi} props={item} stampaj={this.stampaj}></PrihvacenaNarudzbina>))}


                    </div > : <TrenutnaPorudzbina prikazi={this.prikazi} narudzbina={this.state.trenutnoPrikazana} prihvati={this.prihvati} odbij={this.odbij} spremi={this.spremi} zavrsi={this.zavrsi} otkazi={this.otkazi} stampaj={this.stampaj}></TrenutnaPorudzbina>))
    }
}

const stylesDesktop = {
    dugmic: {
        borderRadius: 2
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto', backgroundColor: 'white'
    },
    podContainer: {
        display: 'flex',
        width: '33.3vw',
        flexDirection: 'column',
        height: "90vh"
    },
    instrukcije: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 0,
        alignSelf: 'end',
        alignItems: 'center',
        height: "10vh"
    },
    instrukcijaKontis: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderRadius: 5
    },
    instrukcija: {
        width: 40, height: 40, borderRadius: 40, backgroundColor: 'gray'
    },
    rush: {
        width: '100%', backgroundColor: 'white', marginTop: 10, display: 'flex', justifyContent: 'center'
    },
    brzOdgovor: {
        marginTop: 5, display: 'flex', justifyContent: 'center', alignSelf: 'center', width: '70%'
    },
    izbaciStavkuKontejner: {
        width: '100%',
        height: '10%',
        backgroundColor: "#f5f5f5",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 5,
        borderRadius: 5,
        marginTop: 10
    },
    izbaciStavkuDugme: {
        alignSelf: 'flex-end', width: "100%", marginTop: 5
    }

}
const stylesMobile = {
    container: {

    }
}