import React, { Component } from "react";
import { Button, Container } from 'react-bootstrap'
import Compressor from 'compressorjs';

const IMAGEHOST = "https://guest.fra1.digitaloceanspaces.com";

export class Option extends Component {
    constructor(props) {
        super(props)
        this.naziv = this.props.props.naziv;
        this.slika = this.props.props.slika;
        this.racunajSamoJednom = this.props.props.racunajSamoJednom;
        this.maxkolicina = this.props.props.maxkolicina;
        this.cena = this.props.props.cena;
        this.identifikator = this.props.props.identifikator;
        this.slikainp = React.createRef();
        this.state = { index: this.props.index }
    }
    updateOption = () => {
        if (this.maxkolicina <= 1) this.racunajSamoJednom = true;
        if (!this.racunajSamoJednom && this.maxkolicina > this.props.parent.max) this.maxkolicina = this.props.parent.max;
        this.props.updateoption({ naziv: this.naziv, cena: this.cena, slika: this.slika, maxkolicina: this.maxkolicina, identifikator: this.identifikator, racunajSamoJednom: this.racunajSamoJednom }, this.identifikator)
    }
    render() {
        let self = this;
        return (
            <Container {...this.props} style={styles.container}>
                <img
                    style={styles.slika}
                    src={this.slika ? IMAGEHOST + this.slika : IMAGEHOST + "/slike/guestreact/dodajsliku.png"}
                    onClick={() => {
                        this.slikainp.current.click();
                    }}
                ></img>
                <input
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    ref={this.slikainp}
                    onChange={(event) => {
                        const image = event.target.files[0];
                        new Compressor(image, {
                            maxWidth: 300,
                            maxHeight: 300,
                            quality: 0.8,
                            success: (compressedResult) => {
                                global.socket.emit("uploadujSliku", { slika: compressedResult, lokacija: "opcije" });
                                global.socket.once("odgovorNaUploadSlike", function (rez) {
                                    if (rez.uspeh) {
                                        self.slika = rez.url;
                                        self.updateOption();
                                    }
                                    else {
                                        alert("Molimo pokusajte ponovo");
                                    }
                                })
                            },
                        });
                    }}
                />
                <Container {...this.props} style={styles.container2}>
                    <Container {...this.props} style={styles.containerIn}>
                        <a>Naziv</a>
                        <input style={styles.input} className="form-control" value={this.naziv}
                            onChange={e => {
                                this.naziv = e.target.value;
                                this.updateOption()
                            }}
                        ></input>
                        <img
                            onClick={() => {
                                this.props.removeOption(this.identifikator)
                            }}
                            src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2F0%2F7%2Fe%2Fa%2F12074327311562940906milker_X_icon.svg.hi.png&f=1&nofb=1"
                            style={{ width: '3vw', height: '3vw', alignSelf: "flex-start", marginTop: 5, marginLeft: 10 }}
                        />
                    </Container>
                    <Container {...this.props} style={styles.containerIn}>
                        <a>Cena</a>
                        <input
                            onChange={e => {
                                if (e.target.value >= 0) {
                                    this.cena = e.target.value;
                                    this.updateOption()
                                }
                            }}
                            style={styles.input} className="form-control" value={this.cena} type="number"></input>
                    </Container>
                    <Container {...this.props} style={styles.containerIn}>
                        <a>Max kolicina </a>
                        <input
                            onChange={e => {
                                if (e.target.value > 0) {
                                    this.maxkolicina = Math.max(1, this.racunajSamoJednom ? e.target.value : Math.min(e.target.value, this.props.parent.max));
                                    this.updateOption()
                                }
                            }}
                            style={styles.input} className="form-control" value={this.maxkolicina} type="number"></input>
                    </Container>

                    <Container {...this.props} style={styles.containerIn}>
                        <label for={"opcijaID" + this.identifikator}>Računaj samo jednom </label>
                        <input

                            disabled={this.maxkolicina <= 1}
                            id={"opcijaID" + this.identifikator}
                            checked={this.racunajSamoJednom}
                            onChange={e => {
                                this.racunajSamoJednom = e.target.checked;
                                self.updateOption();
                            }}
                            type={"checkbox"}
                            style={styles.inputCB} ></input>
                    </Container>

                </Container>
            </Container>
        )
    }
}
const styles = {
    container: {
        display: 'flex',
        width: '90%', backgroundColor: 'black',
        flexDirection: 'row', backgroundColor: 'white', borderRadius: 5, marginTop: 15
    },
    container2: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
    },
    input: {
        margin: 5, height: '6vh', width: '50%', borderRadius: 5
    },
    inputCB: {
        margin: 10, height: '3vh', width: '50%', borderRadius: 5
    },
    slika: {
        height: '20vh',
        width: '20vh', borderRadius: 10, margin: 10
    }, containerIn: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
    }
}