import { Component } from "react";
import { Navbar1 } from "../../Components/Navbar1";
import {Container} from 'react-bootstrap'

export class AboutUs extends Component{
    render() {
        return <div>
            <Navbar1/>
            <h1>about</h1>
        </div>;
    }
}