import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { Container } from 'react-bootstrap'

const Footer = () => {
  return (
    <MDBFooter style={{ backgroundColor: "#f4ff60" }} className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6" style={{ display: 'flex', flexDirection: 'column' }}>
            <h5 className="title">Pravna dokumenta: </h5>
            <br />
            <a href="https://www.freeprivacypolicy.com/live/5f3b02d2-7a14-4245-8ccc-573d81f18cf0" style={{ color: 'black' }}>
              Politika privatnosti
            </a>
            <a href="https://www.freeprivacypolicy.com/live/17e492c1-ab48-4767-978d-541bbb2cb44e" style={{ color: 'black', marginBottom: 10 }}>
              Uslovi i odredbe
            </a>
          </MDBCol>

          <MDBCol md="6">
            <Container style={styles.kont}>
              <a>Pišite nam na: <a style={{ color: "black" }} href="mailto:support@guest.rs">support@guest.rs</a></a>
              <a>Pozovite nas: <a style={{ color: "black" }} href="tel:+381637443242">+381637443242</a></a>
              <Container>
                <a>I zapratite nas na </a>
                <a href="https://Instagram.com/guest.app" style={{ color: "black" }}><img src="https://workingwithdog.com/wp-content/uploads/2016/05/new_instagram_logo.jpg" style={{ width: 20, height: 20, borderRadius: 5 }} />
                  @guest.app</a>
              </Container>
            </Container>

            <p class="love" style={{ color: 'black', fontFamily: "monospace" }}>Napravljeno s <i class="icon ion-heart">💛</i> od strane Guest-ovih osnivača</p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a> Guest Inc. </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;

const styles = {
  txt: {

  },
  kont: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: 20
  }
}