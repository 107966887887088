import { Component } from "react";
import { Navbar1 } from "../../Components/Navbar1";
import {Container} from 'react-bootstrap'
import { isMobile } from "react-device-detect";
export class Pricing extends Component{
    render() {
        return <div style={{display: 'flex',alignItems: 'center',flexDirection: 'column'}}>
            <Navbar1> </Navbar1>
            <div style={{width:isMobile?"60vw":"30vw",marginBottom:"5vh",marginTop:"5vh"}}>
<a style={{alignSelf: 'center',fontSize:20,color:"gray"}}> Prva 3 meseca su besplatna i u početku nije potrebna kreditna kartica. Posle ta 3 meseca možete izabrati paket koji odgovara vašim potrebama.</a>
</div>

        </div>;
    }
}

const styles={
    container: {
        backgroundColor:"#f5f5f5",
        evelation:10,width:isMobile?"80vw":"40vw",height:"40vh",borderRadius:20,display:"flex",justifyContent:"center",textAlign:"center",flexDirection:"column"
    }
}